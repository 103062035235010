@mixin lang {

    $text: #fff;
    $text-hover: #fb9b1f;

    .lang{
        display: flex;
        @include reset-ul;
        flex-direction: column;

        li{
            line-height: 1.2;
        }

        a{
            font-size: 1.4rem;
            color: $text;
            @include font-regular;
            text-transform: uppercase;

            &:hover,
            &.active{
                color: $text-hover;
                text-decoration: none;
            }

            &.active{
                @include font-bold;
            }
        }
    }
}

@include lang;
