.title-404{
    margin-top: 0;
    margin-bottom: 9px;
    font-size: 3.8rem;
    color: #4a7eff;
    text-transform: uppercase;
}

.title-404-text{
    font-weight: bold;
    color: #4a7eff;
}

.title-404-back{
    margin-top: 4.7rem;
    color: #4a7eff;

    a{
        color: #4a7eff;
    }
}

.bnt-404{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: 0 16px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff !important;
    text-transform: uppercase;
    background-color: #015496;
    border-radius: 29px;

    &:hover{
        text-decoration: none;
    }
}
