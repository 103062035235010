@charset "UTF-8";
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

/*
.address__city{
  position: relative;
  display: inline-block!!!;

  &::before,
  &::after{
      @include pseudo();
      @include centerer();
  }

  @include line-middle-text(12px);
*/
html {
  min-width: 320px;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 320px;
  height: 100%;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
a {
  overflow-wrap: break-word;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.fixed-top {
  position: fixed;
  top: -1px;
  right: 0;
  left: 0;
  z-index: 1030;
}

.col, .header__menu {
  flex: 1 1 auto;
  min-width: 0;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.overflow_block {
  overflow-x: auto;
}

.main {
  flex-grow: 1;
}

.inner {
  flex: 1 1 auto;
}

.inner img {
  max-width: 100%;
  height: auto;
}

/* для картинок в таблицах, чтобы не сжимались */
table img {
  max-width: initial;
}

.lazyload {
  opacity: 0;
}

.lazyloading {
  background: #f7f7f7 url("/desimages/loader.webp") no-repeat center;
  opacity: 1;
  transition: opacity 300ms;
}

.gl-pad {
  padding: 56px 50px 100px 70px;
}

.gl-pad.list-anonce {
  padding-top: 40px;
  padding-bottom: 60px;
}

.gl-pad.list-anonce--smi {
  padding-top: 65px;
  padding-bottom: 68px;
}

@media (max-width: 1514.98px) {
  .gl-pad {
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 440px) {
  .gl-pad {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.gl-date {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  display: block;
  margin-top: 6px;
  font-size: 1.1rem;
  color: #a3a3a3;
}

.list-news__item-big .gl-date {
  color: #fff;
}

.list-anonce .js-flex-start {
  justify-content: flex-start;
}

.list-anonce .js-flex-start > li {
  margin-right: 40px;
}

.inner-wrapper {
  padding: 57px 70px 130px 70px;
}

@media (max-width: 859.98px) {
  .inner-wrapper {
    padding: 30px 15px;
  }
}

.inner-title-section {
  margin-top: 0;
  margin-bottom: 3.6rem;
  font-size: 3.6rem;
  color: #353534;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.to-all-item {
  display: inline-block;
  margin-top: 4rem;
  color: #fb9b1f;
}

.to-all-item:hover {
  color: #fb431f;
}

.header__row-1,
.header__row-11 {
  position: absolute;
  top: 60px;
  left: 0;
  display: flex;
  width: 100%;
}

@media (max-width: 1214.98px) {
  .mobile-header .header__row-1, .mobile-header
  .header__row-11 {
    top: 6px;
  }
}

.header__lang {
  flex-shrink: 0;
  width: 72px;
  padding: 14px 0;
  padding-left: 6px;
  background-color: #71941c;
}

.header__gerb {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 136px;
  background-color: #71941c;
}

.header__gerb a {
  flex-shrink: 0;
  max-width: 54px;
}

.header__gerb a + a {
  margin-left: 10px;
}

.header__name {
  flex-shrink: 0;
  width: 365px;
  padding-right: 60px;
  background: #71941c;
  background: linear-gradient(90deg, #71941c 80%, #81a52a 100%);
}

@media (max-width: 1214.98px) {
  .mobile-header .header__name {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
  }
}

.header__name a {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  line-height: 1.3;
  color: #fff;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

@media (max-width: 1214.98px) {
  .header__name a {
    max-width: 320px;
  }
}

.header__name a:hover {
  text-decoration: none;
}

.header__menu {
  background-color: #81a52a;
}

.header__row-2 {
  margin-top: 100px;
}

.block-search {
  padding: 0 12px;
  padding-bottom: 1.2rem;
}

.block-left-menu {
  padding-bottom: 1.2rem;
}

.mobile-header {
  display: none;
}

@media (max-width: 1214.98px) {
  .mobile-header {
    display: block;
  }
}

@media (max-width: 444px) {
  .mobile-header {
    display: none !important;
  }
}

@media (max-width: 1214.98px) {
  .title-img {
    display: none;
  }
}

#main-nav {
  display: none;
}

#mobile_menu {
  display: none;
}

.line-blue {
  height: 5px;
  background-color: #1a6b9f;
}

.mobile-button-menu {
  right: 16px;
  width: 50px !important;
  padding-right: 20px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: right center;
}

@media (max-width: 330px) {
  .header__row-2 .mobile-button-menu {
    display: none !important;
  }
}

.mobile-header__low {
  display: none;
}

@media (max-width: 444px) {
  .mobile-header__low {
    display: block !important;
  }
}

.header__row-11__low {
  top: 0 !important;
  flex-wrap: wrap;
  background-color: #fb9b1f;
}

.header__row-11__low .header__lang {
  width: 100%;
  padding: 0;
}

.header__row-11__low .header__name {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header__row-11__low .header__name a {
  line-height: 1.2;
}

.header__row-11__low .header__gerb {
  flex-direction: column;
  width: 54px;
  padding-top: 5px;
}

.header__row-11__low .header__gerb img {
  max-width: 30px;
}

.header__row-11__low .header__gerb a {
  margin: 0;
}

.header__row-11__low .mobile-header__low__row {
  display: flex;
}

.header__row-11__low .mobile-button-menu {
  right: 7px;
}

.header__row-11__low .header-line-1 {
  position: relative;
  flex-grow: 1;
  padding-left: 7px;
}

.header__row-11__low .lang {
  flex-direction: row;
  align-items: center;
  background-color: #fb9b1f;
}

.header__row-11__low .lang li {
  width: 50px;
}

.header__row-11__low .lang a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  height: 40px;
}

.header__row-11__low .lang a.active {
  background-color: #71941c;
}

.header__row-11__low .lang a:hover {
  color: #fb9b1f;
  background-color: #71941c;
}

.border-accent {
  border-top: 6px solid #fb9b1f;
}

.g-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1910px;
  margin: 0 auto;
}

.g-container .col-left {
  flex-shrink: 0;
  width: 330px;
  background-color: #587c29;
}

@media (max-width: 1514.98px) {
  .g-container .col-left {
    width: 290px;
  }
}

@media (max-width: 1214.98px) {
  .g-container .col-left {
    display: none;
  }
}

.g-container .footer {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.block-social {
  display: flex;
  justify-content: space-between;
}

.inner__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
}

@media (max-width: 914.98px) {
  .inner__row {
    flex-direction: column;
  }
}

.inner__col-1 {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  max-width: 1000px;
  margin-right: 20px;
}

@media (max-width: 914.98px) {
  .inner__col-1 {
    margin-right: 0;
  }
}

.inner__col-2 {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  flex-basis: 360px;
  flex-shrink: 0;
  margin-left: auto;
}

@media (max-width: 914.98px) {
  .inner__col-2 {
    margin-top: 3rem;
  }
}

.footer {
  padding: 33px 73px;
  background-color: #81a52a;
}

@media (max-width: 914.98px) {
  .footer {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 440px) {
  .footer {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.footer a {
  color: #c0f0c8;
  text-decoration: none;
}

.footer a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer span {
  color: #c0f0c8;
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
}

@media (max-width: 1514.98px) {
  .footer__row {
    flex-wrap: wrap;
  }
  .footer__row > div {
    margin-bottom: 25px;
  }
}

@media (max-width: 599.98px) {
  .footer-col-1,
  .footer-col-2,
  .footer-col-3,
  .footer-col-4 {
    margin-bottom: 30px;
  }
}

.footer-col-1 {
  width: 100%;
  max-width: 307px;
  padding-right: 73px;
}

.footer-col-1 a,
.footer-col-1 span {
  display: block;
  font-size: 1.4rem;
}

.footer-col-1 div {
  color: #c0f0c8;
}

.footer-col-1 div:first-child {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.footer-col-1 div:last-child {
  margin-top: 12px;
  font-size: 1.4rem;
}

.footer-col-1__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.footer-col-2 {
  width: 100%;
  max-width: 400px;
}

.footer-col-2 .gl-pad {
  padding: 0;
}

@media (max-width: 1514.98px) {
  .footer-col-2 {
    max-width: 280px;
  }
}

.footer-col-2 ul {
  padding: 0;
  margin: 0;
}

.footer-col-2 ul li {
  padding: 0;
  margin: 0;
}

.footer-col-2 ul li::before {
  display: none;
}

.footer-col-2 ul li {
  padding-bottom: 14px;
  padding-left: 33px;
  font-size: 1.4rem;
  color: #c0f0c8;
}

.footer-col-3 {
  width: 100%;
  max-width: 480px;
}

@media (max-width: 1514.98px) {
  .footer-col-3 {
    max-width: 430px;
  }
}

.footer-col-4 {
  width: 100%;
  max-width: 540px;
}

.footer-col-4 a {
  display: inline-block;
  margin-right: 10px;
}

.footer-col-4__title {
  display: block;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #c0f0c8;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.footer__title {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.footer__year {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}

.footer-col-3 .sm1 {
  margin: 0;
  margin-bottom: 2px;
}

.footer-col-3 .sm1 li {
  padding-left: 4rem;
}

.footer-col-3 .sm1 li::before {
  top: 1.4rem;
  width: 30px;
  height: 2px;
  background-color: #687c29;
}

.cicon1,
.cicon2,
.cicon3,
.cicon4 {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 20px;
}

.cicon1 {
  background-image: url("/desimages/icons_adress.svg");
}

.cicon2 {
  background-image: url("/desimages/icons_phone.svg");
}

.cicon3 {
  background-image: url("/desimages/icons_e-mail.svg");
}

.cicon4 {
  background-image: url("/desimages/icons_contact.svg");
}

.hv_pages,
.pages {
  display: flex;
  padding-top: 2rem;
  margin-top: 8px;
  border-top: 1px solid #587c29;
}

@media (max-width: 914.98px) {
  .hv_pages,
  .pages {
    flex-wrap: wrap;
  }
}

@media (max-width: 599.98px) {
  .hv_pages,
  .pages {
    margin-top: 15px;
  }
}

.opinion-list-inner .hv_pages {
  margin-top: 30px;
}

.hv_page_sel,
.hv_pages_item a,
.page_item,
.p_next_block,
.p_prev_block {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  height: 26px;
  padding: 10px;
  margin-right: 1rem;
  font-size: 1.6rem;
  line-height: 1;
  color: #587c29;
  text-align: center;
  text-decoration: none;
  border-radius: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.hv_page_sel:hover,
.hv_pages_item a:hover,
.page_item:hover,
.p_next_block:hover,
.p_prev_block:hover {
  color: #fff;
  text-decoration: none;
  background-color: #fb431f;
}

.p_next,
.p_prev {
  width: auto;
  border-radius: 0;
}

.p_prev {
  padding-left: 0;
  padding-left: 10px;
  margin-left: -10px;
}

.hv_page_sel,
.page_item--selected,
.page_item_selected {
  width: 26px;
  color: #fff;
  background-color: #fb9b1f;
}

.hv_page_sel:hover,
.page_item--selected:hover,
.page_item_selected:hover {
  color: #000;
  text-decoration: none;
}

.link-button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  padding: 8px 24px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #71941c;
  border: 1px solid transparent;
  border-radius: 20px;
  border-radius: 0;
  transition: none;
}

.link-button:hover {
  text-decoration: none;
  background-color: #fb431f;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.4);
  box-shadow: none;
}

@media (max-width: 914.98px) {
  .link-button {
    padding: 15px;
  }
}

.taxonomy {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 3.2rem;
}

.taxonomy li {
  padding: 0;
  margin: 0;
}

.taxonomy li::before {
  display: none;
}

.taxonomy a,
.taxonomy span {
  font-size: 1.1rem;
  color: #6d6c6c;
  text-transform: uppercase;
}

.taxonomy a:hover {
  color: #6d6c6c;
  text-decoration: underline;
}

/* stylelint-disable */
.ya-share2__list {
  padding: 0;
  margin: 0;
  display: flex !important;
  flex-direction: column !important;
}

.ya-share2__list li {
  padding: 0;
  margin: 0;
}

.ya-share2__list li::before {
  display: none;
}

@media (max-width: 599.98px) {
  .ya-share2__list {
    flex-direction: row !important;
  }
}

.ya-share2__item {
  margin-bottom: 1rem !important;
}

.ya-share2__icon {
  width: 45px !important;
  height: 45px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 34px 34px !important;
}

.ya-share2__badge {
  border-radius: 50% !important;
}

.counters_share {
  margin-bottom: 3px;
}

.social {
  display: flex;
  padding: 0;
  margin: 0;
}

.social li {
  padding: 0;
  margin: 0;
}

.social li::before {
  display: none;
}

.social img {
  min-width: 26px;
  max-width: initial;
}

.social a {
  display: flex;
  align-items: center;
  min-height: 54px;
  padding: 0 6px;
}

.social a:hover {
  background-color: #81a53a;
}

.social.vsv img {
  min-width: 44px;
}

.lang {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
}

.lang li {
  padding: 0;
  margin: 0;
}

.lang li::before {
  display: none;
}

.lang li {
  line-height: 1.2;
}

.lang a {
  font-size: 1.4rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.lang a:hover, .lang a.active {
  color: #fb9b1f;
  text-decoration: none;
}

.lang a.active {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.title-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 36px;
}

@media (max-width: 859.98px) {
  .title-section {
    flex-wrap: wrap;
  }
}

.title-section__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-right: 3.3rem;
  font-size: 3.6rem;
  color: #687c29;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.title-section__title:hover {
  color: #fb9b1f;
  text-decoration: none;
}

@media (max-width: 440px) {
  .title-section__title {
    font-size: 2rem;
  }
}

.title-section__all {
  height: 100%;
  padding-right: 1.4rem;
  padding-left: 1.4rem;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fb9b1f;
}

.title-section__all a,
.title-section__all span {
  display: flex;
  align-items: center;
  min-height: 2.2rem;
  font-size: 1rem;
  color: #fff;
}

.title-section__all a:hover,
.title-section__all span:hover {
  text-decoration: none;
  background-color: #fb431f;
}

.title-section__all:hover {
  text-decoration: none;
  background-color: #fb431f;
}

.list-resource .title-section__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
}

.list-resource .title-section {
  margin-bottom: 1.6rem;
}

.media-tab .title-section {
  margin-bottom: 10px;
}

.list-news {
  padding-bottom: 71px;
  background-color: #faf1e5;
}

@media (max-width: 859.98px) {
  .list-news {
    padding-bottom: 30px;
  }
}

.list-news__row {
  display: flex;
  padding: 0;
  margin: 0;
}

.list-news__row li {
  padding: 0;
  margin: 0;
}

.list-news__row li::before {
  display: none;
}

@media (max-width: 859.98px) {
  .list-news .list-news__row {
    flex-direction: column;
  }
  .list-news .list-news__row .list-news__item {
    padding-left: 0;
  }
}

.list-news__row-big {
  padding: 0;
  margin: 0;
}

.list-news__row-big li {
  padding: 0;
  margin: 0;
}

.list-news__row-big li::before {
  display: none;
}

@media (max-width: 1514.98px) {
  .list-news__row-big {
    max-width: 435px;
  }
  .list-news__row-big img {
    max-width: 435px;
  }
}

@media (max-width: 859.98px) {
  .list-news__row-big {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 859.98px) {
  .list-news__row-big a {
    max-width: 100%;
  }
  .list-news__row-big img {
    max-width: 100%;
  }
}

.list-news__row-small {
  display: flex;
  padding: 0;
  margin: 0;
}

.list-news__row-small li {
  padding: 0;
  margin: 0;
}

.list-news__row-small li::before {
  display: none;
}

@media (max-width: 1514.98px) {
  .list-news__row-small {
    flex-direction: column;
  }
}

@media (max-width: 1514.98px) {
  .list-news__row-small .list-news__item a {
    display: flex;
    align-items: flex-start;
  }
}

@media (max-width: 440px) {
  .list-news__row-small .list-news__item a {
    flex-direction: column;
  }
}

@media (max-width: 440px) {
  .list-news__row-small .list-news__item a img {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
}

@media (max-width: 1514.98px) {
  .list-news__row-small .list-news__item {
    flex-basis: 0;
    padding-left: 26px;
    margin-bottom: 35px;
    margin-left: 0;
  }
}

@media (max-width: 859.98px) {
  .list-news__row-small .list-news__item {
    margin-left: 0;
  }
}

@media (max-width: 440px) {
  .list-news__row-small .list-news__item {
    padding-left: 0;
  }
}

@media (max-width: 1514.98px) {
  .list-news__row-small .list-news__item img {
    position: relative;
    max-width: 150px;
    margin-right: 20px;
  }
}

.list-news__item-big {
  flex-shrink: 0;
  width: 550px;
}

@media (max-width: 1514.98px) {
  .list-news__item-big {
    width: auto;
  }
}

.list-news__item-big:hover .list-news__name-big {
  margin-right: 2.6rem;
  text-decoration: underline;
}

@media (max-width: 440px) {
  .list-news__item-big:hover .list-news__name-big {
    color: #fb431f;
  }
}

.list-news__item-big:hover .gl-date {
  text-decoration: none;
}

.list-news__item-big a {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 440px) {
  .list-news__item-big a:hover {
    text-decoration: none;
  }
}

.list-news__content {
  position: absolute;
  bottom: 14px;
  left: 0;
  width: 100%;
  padding: 0 20px;
}

@media (max-width: 1514.98px) {
  .list-news__content {
    position: relative;
    bottom: 0;
    padding-left: 0;
  }
  .list-news__content > span {
    color: #353534;
  }
  .list-news__content > .gl-date {
    color: #a3a3a3;
  }
}

@media (max-width: 859.98px) {
  .list-news__content {
    position: absolute;
    bottom: 14px;
    padding-left: 5px;
  }
  .list-news__content > span {
    color: #fff;
  }
  .list-news__content > .gl-date {
    color: #fff;
  }
}

@media (max-width: 440px) {
  .list-news__content {
    position: relative;
    bottom: 0;
  }
  .list-news__content .list-news__name-big {
    color: #353534;
  }
  .list-news__content .gl-date {
    color: #a3a3a3;
  }
}

.list-news__name-big {
  display: block;
  font-size: 2.4rem;
  color: #fff;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

li.list-news__item {
  flex-basis: 274px;
  flex-grow: 1;
  width: 100%;
  margin-left: 26px;
}

li.list-news__item a {
  display: block;
  color: #353534;
}

li.list-news__item a:hover {
  color: #fb431f;
  text-decoration: none;
}

li.list-news__item a:hover .list-news__name-small {
  color: #fb431f;
  text-decoration: underline;
}

li.list-news__item img {
  display: block;
}

.list-news__content-small {
  display: block;
  margin-top: 1.5rem;
}

@media (max-width: 1514.98px) {
  .list-news__content-small {
    position: relative;
    top: -5px;
    margin-top: 0;
  }
}

.list-news__name-small {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  display: block;
  font-size: 1.6rem;
  color: #353534;
}

.top-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: 100%;
}

.top-menu li {
  padding: 0;
  margin: 0;
}

.top-menu li::before {
  display: none;
}

.top-menu a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2.8rem;
  font-size: 1.4rem;
  color: #fff;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.top-menu a:hover {
  text-decoration: none;
  background-color: #fb9b1f;
}

.top-menu__active {
  background-color: #fb9b1f;
}

.menu-arrow {
  padding-right: .3rem;
  padding-left: 1.2rem;
  color: #fff;
  cursor: pointer;
}

.menu-arrow img {
  max-width: initial;
}

.left-menu {
  padding: 0;
  margin: 0;
}

.left-menu li {
  padding: 0;
  margin: 0;
}

.left-menu li::before {
  display: none;
}

.li-row {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-right: 1.5rem;
  padding-bottom: 7px;
  padding-left: 7.5rem;
}

.left-menu li {
  position: relative;
}

.left-menu li:hover {
  background-color: #81a52a;
}

.left-menu li:hover::before, .left-menu li:hover::after {
  background-color: #fff;
}

.left-menu li::before {
  position: absolute;
  display: block;
  content: "";
  top: 1.7rem;
  width: 4.4rem;
  height: auto;
  padding-top: 1px;
  background-color: #fb9b1f;
  border-radius: 0;
  transform: none;
}

.left-menu li::after {
  position: absolute;
  display: block;
  content: "";
  top: 1.7rem;
  left: 5.4rem;
  width: .6rem;
  height: auto;
  padding-top: 1px;
  background-color: #fb9b1f;
  border-radius: 0;
}

li.left-menu-open-js {
  background-color: #81a52a;
}

li.left-menu-open-js::after,
li.left-menu-open-js::before {
  background-color: #fff;
}

.left-menu a {
  font-size: 1.4rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.left-menu a:hover {
  text-decoration: none;
}

.left-menu ul li::before {
  display: none;
}

.left-menu ul li::after {
  background-color: #fff;
}

.left-menu ul .li-row:hover {
  background-color: #587c29;
}

.left-menu ul ul li::before,
.left-menu ul ul li::after {
  display: none;
}

.left-menu ul ul .li-row {
  padding-left: 88px;
}

.left-menu ul ul ul .li-row {
  padding-left: 106px;
}

.left-menu ul {
  display: none;
}

.left-menu-open-js > ul {
  display: initial;
}

.selected888 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.li-row + ul a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.inner-list-news__print a {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  font-size: 0;
  background-image: url("/desimages/print.svg");
  background-repeat: no-repeat;
  background-position: left center;
}

.inner-news__row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

@media (max-width: 599.98px) {
  .inner-news__row {
    flex-direction: column;
  }
}

.inner-news__share {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 90px;
}

@media (max-width: 599.98px) {
  .inner-news__share {
    margin-top: 30px;
  }
}

.inner-news__date {
  margin-bottom: 1.4rem;
}

.inner-news__image {
  margin-bottom: 4rem;
}

.inner-news__tags {
  margin-top: 3.5rem;
}

.inner-news__tags ul {
  display: flex;
}

.inner-news__tags li {
  padding: 0;
  margin: 0;
  margin-right: 20px;
}

.inner-news__tags a {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  color: #6d6c6c;
}

.inner-news__tags li::before {
  display: none;
}

.photo-list .inner-list-news__image a {
  display: block;
}

.photo-list .inner-list-news__row {
  flex-direction: column;
}

.photo-list .inner-list-news__image {
  max-width: 333px;
  margin: 0;
}

.photo-list .inner-list-news__image span {
  display: block;
  margin-top: 1.4rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
}

.photo-list .inner-list-news__item {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.photo-list .inner-list-news__item:nth-child(1) .inner-list-news__row,
.photo-list .inner-list-news__item:nth-child(2) .inner-list-news__row,
.photo-list .inner-list-news__item:nth-child(3) .inner-list-news__row {
  padding-top: 0;
  border-top: none;
}

.photo-list .inner-list-news__text:hover {
  text-decoration: none;
}

.photo-list .inner-list-news__text {
  margin-top: 2px;
}

.photo-list .custom-date {
  margin-top: 0;
}

.red {
  color: #fb431f;
}

.fb_form_no {
  margin-bottom: 10px;
  color: #fb431f;
}

.fb_form_ok {
  margin-bottom: 10px;
  color: green;
}

.fb_form {
  max-width: 1045px;
  padding: 4.2rem 78px 4rem 28px;
  margin-bottom: 10rem;
  background-color: #faf1e5;
}

.fb_form__row {
  display: flex;
  margin-bottom: 2.7rem;
}

@media (max-width: 914.98px) {
  .fb_form__row {
    flex-direction: column;
  }
}

.fb_form__row > label {
  flex-shrink: 0;
  width: 288px;
  padding-right: 1rem;
  font-size: 1.4rem;
}

.fb_form .el_input {
  width: 100%;
}

.fb_form .el_input select,
.fb_form .el_input textarea,
.fb_form .el_input input[type=text] {
  display: block;
  width: 100%;
  max-width: 670px;
  height: 3rem;
  padding: 10px 16px;
  background-color: #fff;
  border: none;
}

.fb_form .el_input select:focus,
.fb_form .el_input textarea:focus,
.fb_form .el_input input[type=text]:focus {
  box-shadow: inset 0 0 0 2px rgba(74, 126, 255, 0.4);
}

.fb_form input[type=submit] {
  margin-top: 6rem;
  margin-bottom: 0;
}

.fb_form input[type=submit]:hover {
  background-color: #fb431f;
}

.fb_form .el_input textarea {
  min-height: 144px;
}

.fb_form .el_input .fill-field,
.fb_form .for_code .fill-field {
  display: none;
}

.fb_form__text-required {
  margin-bottom: 5px;
  font-size: 1.4rem;
}

.optional-text-after {
  font-size: 1.4rem;
}

.optional-text-before {
  margin-bottom: 5rem;
}

.optional-text-before a {
  color: #587c29;
}

.optional-text-before a:hover {
  color: #fb431f;
}

.fb_form .sub_title {
  max-width: 677px;
}

.capcha-iframe-wrapper {
  display: flex;
}

@media (max-width: 599.98px) {
  .capcha-iframe-wrapper {
    flex-direction: column;
  }
}

.capcha-iframe-wrapper__image {
  flex-shrink: 0;
  width: 174px;
}

.capcha-iframe-wrapper__image a {
  font-size: 1.4rem;
}

.capcha-iframe-wrapper__input {
  flex-shrink: 0;
  width: 166px;
}

.obr__form {
  width: 100%;
  padding: 30px 76px 11px 26px;
  margin-top: 26px;
  font-size: 19px;
  background-color: #f5f1ed;
  border: 1px solid #d9d5cf;
}

@media (max-width: 1214.98px) {
  .obr__form {
    max-width: 100%;
  }
}

@media (max-width: 914.98px) {
  .obr__form {
    padding: 20px 20px;
  }
}

.obr__field-required {
  margin-bottom: 10px;
  font-weight: bold;
}

.obr__field-required sup {
  color: #fb431f;
}

.obr__form label,
.obr__attach-file-title,
.digits_title,
.psevdo-label {
  flex-basis: 250px;
  flex-shrink: 0;
  padding-top: 5px;
  margin-right: 30px;
  line-height: 1;
  color: #919191;
}

@media (max-width: 914.98px) {
  .obr__form label,
  .obr__attach-file-title,
  .digits_title,
  .psevdo-label {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
}

.obr__form sup {
  top: -.1em;
  color: #fb431f;
}

.obr__form > div {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 914.98px) {
  .obr__form > div {
    flex-direction: column;
    align-items: flex-start;
  }
}

.obr__form input:not([type=submit]),
.obr__form textarea,
.obr__form select,
.obr__file,
.obr__wrrapper-code-block,
.search-date-restrict {
  width: 100%;
  width: 100%;
  padding: 2px 15px;
  color: #919191;
  background-color: #fff;
  border: 1px solid transparent;
}

.obr__form textarea {
  flex-basis: 150px;
}

.obr__form .el_obr_fill_field {
  display: none;
  width: 100%;
  margin-top: 5px;
  margin-left: 280px;
  color: #fb431f;
  visibility: visible !important;
}

@media (max-width: 914.98px) {
  .obr__form .el_obr_fill_field {
    margin-left: 0;
  }
}

.obr__form input[type=file] {
  display: none;
}

.obr__file {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;
}

@media (max-width: 599.98px) {
  .obr__file {
    flex-direction: column-reverse;
    padding: 0;
  }
}

.obr__file .el_obr_fill_field,
.obr__result .el_obr_fill_field {
  margin-left: 0;
}

.obr__descript {
  width: 350px;
  margin-left: 15px;
  color: #919191;
}

.obr__descript > * {
  overflow-wrap: break-word;
}

@media (max-width: 599.98px) {
  .obr__descript {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.obr__form .obr__attach {
  flex-wrap: nowrap;
  align-items: flex-start !important;
}

@media (max-width: 599.98px) {
  .obr__form .obr__attach {
    margin-bottom: 0;
  }
}

.obr__searсh {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  padding: 0 16px 0 16px;
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
}

.attach-file-line {
  flex-shrink: 0;
  width: 150px;
}

@media (max-width: 599.98px) {
  .attach-file-line {
    margin-top: 30px;
  }
}

.attach-file-line input[type=file] {
  display: none;
}

.attach-file-line__close {
  display: inline-block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
  background-image: url("/images/design/icons_close.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px 20px;
}

.attach-file-line__wrapper {
  display: flex;
  align-items: center;
}

.attach-file-line__selected,
.js-no-selected {
  font-size: 1.4rem;
}

.attached__row {
  display: flex;
}

@media (max-width: 599.98px) {
  .attached__row {
    flex-direction: column-reverse;
  }
}

.attached__text {
  padding-left: 10px;
  font-size: 1.4rem;
}

.obr__wrrapper-code-block {
  display: flex;
  background-color: transparent;
  border: none;
}

.obr__cap a {
  display: block;
  color: #fb431f;
}

.obr__submit {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 0 !important;
}

@media (max-width: 914.98px) {
  .obr__submit {
    flex-direction: row !important;
    justify-content: center !important;
  }
}

.text_after {
  max-width: 895px;
  margin-top: 30px;
  margin-bottom: 25px !important;
}

.attach-file-line__block-name {
  display: none;
}

.obr__attach-file-title {
  flex-shrink: 0;
}

@media (max-width: 599.98px) {
  .obr__attach-file-title {
    margin-bottom: 16px;
    font-weight: bold;
  }
}

#field_16 {
  flex-basis: 55px;
  min-height: 1px !important;
}

.obr__code-box,
.obr__submit {
  width: 100%;
}

.obr__code-box {
  display: flex;
}

.search-date-restrict {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.search-date-restrict > input {
  max-width: 200px;
}

.search-date-restrict .left {
  display: inline-block;
  padding: 0 1rem;
}

.search-date-restrict .well {
  position: relative;
}

.search-date-restrict .well img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  cursor: pointer;
}

.search-date-restrict .well input {
  padding-top: 8px;
}

.search-date-restrict_sort {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.search-date-restrict_sort input {
  flex-basis: auto !important;
  flex-grow: 1 !important;
  width: auto !important;
}

#ext-search {
  display: none;
  margin-bottom: 30px;
}

.ui-datepicker {
  max-width: 255px !important;
}

.ui-datepicker,
.ui-datepicker .ui-datepicker-title select {
  border-color: #4a7eff !important;
}

.ui-datepicker select.ui-datepicker-year {
  color: #4a7eff !important;
}

.ui-datepicker td a,
.ui-datepicker th,
.ui-datepicker select.ui-datepicker-year,
.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year,
.ui-datepicker td span {
  font-size: 19px !important;
}

.ui-datepicker .ui-datepicker-month {
  font-size: 17px !important;
}

.ui-datepicker .ui-datepicker-today a,
.ui-datepicker-today span {
  background-color: #4a7eff !important;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  position: absolute;
  top: 64%;
  left: 50%;
  display: block;
  margin-top: -8px;
  margin-left: -8px;
}

.ui-widget-header .ui-icon {
  background-image: url("/desimages/calendar/ui-icons_222222_256x240.png");
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon {
  display: block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  text-indent: -99999px;
  background-repeat: no-repeat;
}

.ui-datepicker td a:hover {
  color: #121212;
  text-decoration: underline;
  background-color: #feac49;
}

.site-map {
  padding: 0;
  margin: 0;
  /* Reset list styles */
  list-style-type: none;
}

ul.site-map li {
  padding: 0;
  margin: 0;
  background-image: none;
}

ul.site-map li ul {
  margin: 0;
}

.js-site-map-row {
  display: flex;
  justify-content: space-between;
}

.js-site-map-row > span {
  flex-basis: 36%;
  flex-basis: 44%;
}

@media (max-width: 599.98px) {
  .js-site-map-row {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

ul.site-map ul > li {
  padding: 0;
}

ul.site-map > li {
  position: relative;
}

@media (max-width: 599.98px) {
  ul.site-map > li {
    padding-left: 0;
    margin-bottom: 30px;
  }
}

.site-map a {
  text-decoration: none;
}

.site-map a:hover {
  color: #fb431f;
  text-decoration: underline;
}

ul.site-map > li > a {
  font-family: "Open Sans", sans-serif;
  font-size: 2.6rem;
}

ul.site-map ul ul a {
  font-weight: normal;
}

.sitemap_level_item_2 {
  position: relative;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.sitemap_level_item_2::before {
  display: none;
}

.sitemap_level_item_2 > a {
  position: relative;
  display: block;
  padding-left: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: #353534;
}

.sitemap_level_item_3 {
  padding-top: 16px !important;
  padding-bottom: 0 !important;
  padding-left: 36px !important;
}

.sitemap_level_item_3::before {
  top: 2.8rem;
  width: 20px;
}

.sitemap_level_item_3 > a {
  position: relative;
  display: block;
  font-size: 1.6rem;
  color: #353534;
}

.sitemap_level_item_4 {
  padding-left: 20px !important;
}

.sitemap_level_item_4 > a {
  position: relative;
  display: block;
  color: #353534;
}

.site-map .list_inner_2 {
  position: relative;
  padding-top: 30px;
  padding-right: 25px;
  padding-bottom: 35px;
  border-bottom: 1px solid #587c29;
}

.site-map .list_inner_2:first-child {
  padding-top: 0;
  border-top: none;
}

.site-map .list_inner_2 > a {
  color: #587c29;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.site-map .list_inner_2::before {
  display: none;
}

.js-site-map-row > span li:last-child::before {
  content: none;
}

.sitemap_level_item_2:last-child::before {
  content: none;
}

.title-404 {
  margin-top: 0;
  margin-bottom: 9px;
  font-size: 3.8rem;
  color: #4a7eff;
  text-transform: uppercase;
}

.title-404-text {
  font-weight: bold;
  color: #4a7eff;
}

.title-404-back {
  margin-top: 4.7rem;
  color: #4a7eff;
}

.title-404-back a {
  color: #4a7eff;
}

.bnt-404 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  padding: 0 16px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff !important;
  text-transform: uppercase;
  background-color: #015496;
  border-radius: 29px;
}

.bnt-404:hover {
  text-decoration: none;
}

.res-news {
  display: block;
  margin-top: 10px;
  color: red;
}

.search-results-out + .pages__wrapper .pages {
  margin-top: 0;
  border-top: none;
}

.res_number {
  margin-bottom: 20px;
}

.res_number span {
  font-weight: bold;
}

.minp_news_item_data {
  color: #737373;
}

.event_top_date {
  margin-bottom: 0;
  line-height: 1;
  color: #424242;
  text-transform: uppercase;
}

.event__item {
  padding-top: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d7d6d4;
}

.event__title {
  margin-bottom: 20px;
  /*    font-size: 3rem; */
  font-size: 2.4rem;
  font-weight: bold;
  color: #424242;
}

.event__text {
  margin-bottom: 20px;
  margin-bottom: 30px;
  font-size: 1.8rem;
  color: #424242;
}

.event__desc {
  font-size: 1.8rem;
  color: #424242;
}

.event__info {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 35px;
}

.event__img {
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 190px;
  margin-right: 20px;
}

.event__content .custom-date {
  margin-top: 0 !important;
}

.event__link {
  font-size: 1.8rem;
  font-weight: bold;
  color: #424242;
}

.event__link .custom-date {
  margin-top: 0 !important;
}

.tarif {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.tarif li {
  padding: 0;
  margin: 0;
}

.tarif li::before {
  display: none;
}

.tarif .active {
  color: #fff;
  background-color: #fb9b1f;
}

.tarif a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 184px;
  min-height: 42px;
  color: #353534;
  text-align: center;
  background-color: #faf1e5;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.tarif a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #fb431f;
}

ul.tarif li {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

ul.tarif li::before {
  display: none;
}

.tarif__content {
  display: none;
}

.tarif__content a[href$="html"] {
  display: inline-block;
  height: 26px;
  padding-left: 4rem;
  background: url("/desimages/files/icons_text.svg") no-repeat left 3px;
  background-repeat: no-repeat !important;
}

.tarif__content a[href$="html"]:hover {
  background: url("/desimages/files/icons_text1.svg");
}

.js-tarif-insert-block {
  margin-top: 4rem;
}

.js-tarif-insert-block .tarif__content {
  display: block;
}

table#table2 p,
table#table3 p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #353534;
}

table#table2 tr:hover,
table#table3 tr:hover {
  background-color: #faf1e5;
}

table#table2 a,
table#table3 a {
  color: #353534;
}

table#table2 a:hover,
table#table3 a:hover {
  text-decoration: none;
}

table#table2 .fake-link,
table#table3 .fake-link {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 4px 16px;
  margin-left: auto;
  font-size: .9rem;
  color: #81a52a;
  text-transform: uppercase;
  background-color: #faf1e5;
}

table#table2 .fake-link:hover,
table#table3 .fake-link:hover {
  color: #fff;
  background-color: #fb431f;
}

table#table3 {
  width: 100%;
}

#workerer {
  display: block;
  padding-bottom: 3.5rem;
  margin-bottom: 5.5rem;
  border-top: 1px solid #81a52a;
  border-bottom: 1px solid #81a52a;
}

#workerer h2 {
  font-size: 24px;
}

#workerer h3 {
  font-size: 16px;
}

#workerer ul {
  margin-top: 0;
}

#workerer p {
  padding-left: 27px;
}

#info-min {
  display: flex;
}

@media (max-width: 914.98px) {
  #info-min {
    flex-direction: column;
  }
}

#info-left {
  flex-shrink: 0;
  width: 454px;
  padding-left: 51px;
}

#adr,
#tel,
#eml {
  position: relative;
}

#adr::before,
#tel::before,
#eml::before {
  left: -35px;
  width: 24px;
  height: 24px;
  background-image: url("/desimages/icons_adress.svg");
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  content: "";
}

#tel::before {
  background-image: url("/desimages/icons_phone.svg");
}

#eml::before {
  background-image: url("/desimages/icons_e-mail.svg");
}

.quest_ans__init {
  padding: 0;
  margin: 0;
}

.quest_ans__init li {
  padding: 0;
  margin: 0;
}

.quest_ans__init li::before {
  display: none;
}

.quest_ans__quest {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 2rem;
}

.quest_ans__otvet {
  display: block;
}

.quest_ans__ans {
  display: block;
  margin-top: 8px;
  margin-bottom: 0;
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.quest_ans__block {
  padding-bottom: 5rem !important;
  margin-bottom: 4.5rem !important;
  border-bottom: 1px solid #81a52a;
}

.quest_ans__block:last-child {
  border-bottom: none;
}

.managers {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.managers li {
  padding: 0;
  margin: 0;
}

.managers li::before {
  display: none;
}

.managers img {
  display: block;
  float: left;
  max-width: initial;
  margin-right: 30px;
}

.managers li {
  position: relative;
  flex-basis: 670px;
  width: 100px;
  max-width: 670px;
  padding-right: 10px;
  margin-bottom: 75px;
}

.managers li:nth-child(1), .managers li:nth-child(2) {
  background-color: #faf1e5;
}

@media (max-width: 859.98px) {
  .managers li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.managers li a {
  position: absolute;
  bottom: 10px;
  left: 232px;
  display: flex;
  align-items: center;
  height: 100%;
  height: 2.2rem;
  padding-right: 1.4rem;
  padding-left: 1.4rem;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fb9b1f;
  -webkit-box-align: center;
}

@media (max-width: 859.98px) {
  .managers li a {
    position: relative;
    bottom: 0;
    left: 0;
  }
}

.managers li a:hover {
  text-decoration: none;
  background-color: #fb431f;
}

.managers li > p:first-of-type {
  padding-top: 20px;
  font-weight: bold;
}

.managers li > p:nth-child(3) {
  font-size: 2.2rem;
  font-weight: bold;
}

.video-block-width {
  max-width: 1215px;
}

.news_list_one {
  margin-bottom: 40px;
}

.news_list_one .news_list_title {
  margin-top: 15px;
}

.title-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.big-image-block {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 106px;
  padding-left: 106px;
  margin-bottom: 50px;
  background-color: #dbdbdb;
}

@media (max-width: 800px) {
  .big-image-block {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.big-image-block__title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 20px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 859.98px) {
  .big-image-block__title {
    position: relative;
    padding: 10px;
  }
}

.big-image-block__count {
  margin-right: auto;
  margin-left: 24px;
}

.big-image-block__image {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #dbdbdb;
}

@media (max-width: 800px) {
  .big-image-block__image {
    height: auto;
  }
}

@media (max-width: 800px) {
  .big-image-block__img {
    height: auto;
  }
}

.big-image-block__prev,
.big-image-block__next {
  position: absolute;
  top: 0;
  width: 106px;
  height: 100%;
  cursor: pointer;
  background-color: #dbdbdb;
  background-repeat: no-repeat;
  background-size: 78px 78px;
}

@media (max-width: 800px) {
  .big-image-block__prev,
  .big-image-block__next {
    width: 30px;
    background-size: 26px;
  }
}

.big-image-block__prev {
  left: 0;
  background-image: url("/desimages/icons_arrow left.svg");
  background-position: left center;
}

.big-image-block__prev:hover {
  background-image: url("/desimages/icons_arrow left1.svg");
}

.big-image-block__next {
  right: 0;
  background-image: url("/desimages/icons_arrow right.svg");
  background-position: right center;
}

.big-image-block__next:hover {
  background-image: url("/desimages/icons_arrow right1.svg");
}

.slider-thumbs {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.slider-thumbs li {
  padding: 0;
  margin: 0;
}

.slider-thumbs li::before {
  display: none;
}

.slider-thumbs > li {
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 20px;
}

.slider-thumbs li::before {
  content: none;
}

.slider-thumbs img {
  max-width: 100%;
  height: auto;
  max-width: 170px;
  cursor: pointer;
}

.slider-thumbs__selected {
  opacity: .5;
}

.slider-thumbs__slideshow {
  margin-top: 20px;
  text-align: center;
}

.slider-thumbs__slideshow button {
  font-size: 1.6rem;
  border: 1px solid transparent;
}

.minpr_slider_photo_inner_box {
  margin-top: 90px;
}

/********   */
@media only screen and (max-width: 1560px) {
  .photo_prev {
    left: 5px;
  }
  .photo_next {
    right: 5px;
  }
}

.slider_big_img {
  margin-bottom: .75rem;
}

#slider_rubr_title {
  margin-bottom: 3rem;
}

.slider_photo {
  max-width: 795px;
}

.slider_photo_inner {
  margin: 0 -10px;
}

@media (max-width: 914.98px) {
  .slider_photo_inner {
    margin: 0;
  }
}

.slider_photo_inner .slick-disabled {
  display: none !important;
}

.slider_photo_inner .slick-track .slide {
  padding: 0 10px;
}

.photo_prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -8px;
  z-index: 3;
  width: 58px;
  height: 58px;
  margin: auto;
  cursor: pointer;
  background-color: #4c7efd;
  border-radius: 50%;
  opacity: .6;
  transition: all .6s;
}

.photo_prev:hover {
  opacity: 1;
}

.photo_prev::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -6px;
  display: block;
  width: 17px;
  height: 25px;
  margin: auto;
  content: '';
  background: url("/desimages/photo_slider_arrow_prev.png") center no-repeat;
}

.photo_next {
  position: absolute;
  top: 0;
  right: -8px;
  bottom: 0;
  z-index: 3;
  width: 58px;
  height: 58px;
  margin: auto;
  cursor: pointer;
  background-color: #4c7efd;
  border-radius: 50%;
  opacity: .6;
  transition: all .6s;
}

.photo_next:hover {
  opacity: 1;
}

.photo_next::after {
  position: absolute;
  top: 0;
  right: -6px;
  bottom: 0;
  left: 0;
  display: block;
  width: 17px;
  height: 25px;
  margin: auto;
  content: '';
  background: url("/desimages/photo_slider_arrow_next.png") center no-repeat;
}

@media only screen and (max-width: 1560px) {
  .photo_prev {
    left: 5px;
  }
  .photo_next {
    right: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .photo_prev {
    left: -3px;
  }
  .photo_next {
    right: -3px;
  }
}

.big-image-block__fullscreen {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
  background-image: url("/desimages/fullscreen.png");
}

.big-image-block__name-photo {
  display: none;
}

.big-image-block__download {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 20px;
  margin-right: 0;
  margin-left: 10px;
  font-size: 0;
  cursor: pointer;
  background-image: url("/desimages/dowload_icon.png") !important;
}

.hc-nav-1 ul {
  padding: 0;
  margin: 0;
}

.hc-nav-1 ul li {
  padding: 0;
  margin: 0;
}

.hc-nav-1 ul li::before {
  display: none;
}

.hc-nav-1 ul li::before {
  content: none;
}

.search {
  background-color: #81a52a;
}

.search:hover {
  background-color: #fff;
}

.search:hover ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #71941c;
  opacity: 1;
  /* Firefox */
}

.search:hover button {
  background-image: url("/desimages/icons_search-hover.svg");
}

.search__input {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 3.8rem;
}

.search__input input {
  flex-grow: 1;
  padding-left: 20px;
  font-size: 1.1rem;
  color: #d0c0a8;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
}

.search__input input:hover {
  color: #71941c;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d0c0a8;
  opacity: 1;
  /* Firefox */
}

.search__input button {
  flex-shrink: 0;
  width: 34px;
  background-color: transparent;
  background-image: url("/desimages/icons_search.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  border: none;
}

.inner-search .search__input {
  margin-bottom: 2rem;
}

.inner-search .search__input input {
  border: 1px solid #000;
}

.inner-search .search__input input[type=submit] {
  max-width: 126px;
  margin-left: 15px;
  color: #fff;
  background-color: #71941c;
}

.inner-search .search_info {
  margin-bottom: 2rem;
}

.inner-search .search_item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #71941c;
}

.inner-search .search_item:last-child {
  border-bottom: none;
}

.inner-search .d-number {
  min-width: 50px;
}

@media (max-width: 859.98px) {
  .inner-search .d-number {
    min-width: 20px;
  }
}

.inner-search .d-result .search_title {
  font-weight: bold;
  color: #000;
}

.inner-search .news_name.news_date {
  display: flex;
}

.inner-search .news_name.news_date a {
  color: grey;
}

.inner-search .news_name.news_date .date-after-title .gl-date {
  display: inline-block;
  font-size: 1.2rem;
}

.inner-search .search_image {
  margin-right: 20px;
}

.inner-search .search_descript {
  margin-top: 15px;
}

.inner-search .search_descript span {
  font-weight: bold;
  color: #000;
}

.list-anonce__img {
  display: block;
  width: 100%;
  max-width: 176px;
  padding-bottom: 1.5rem;
}

@media (max-width: 599.98px) {
  .list-anonce__img {
    padding-bottom: 0;
    margin-right: 16px;
  }
}

.list-anonce__img img {
  display: block;
}

.list-anonce__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  display: block;
  font-size: 1.6rem;
  color: #353534;
}

.list-anonce--smi a {
  padding: 0;
}

.list-anonce--smi .list-anonce__item {
  border: none;
}

.list-anonce--smi .list-anonce__img {
  max-width: 100%;
}

.list-anonce--smi .list-anonce__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 6px;
}

.list-anonce__row {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list-anonce__row li {
  padding: 0;
  margin: 0;
}

.list-anonce__row li::before {
  display: none;
}

@media (max-width: 599.98px) {
  .list-anonce__row {
    flex-direction: column;
  }
}

.list-anonce__item {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  width: 242px;
  border-left: 4px solid #fb9b1f;
}

@media (max-width: 599.98px) {
  .list-anonce__item {
    width: 100%;
    margin-bottom: 30px !important;
  }
}

.list-anonce__item a {
  display: block;
  padding: 0 12px;
  text-decoration: none;
}

.list-anonce__item a span {
  display: block;
}

.list-anonce__item a:hover {
  text-decoration: none;
}

.list-anonce__item a:hover .list-anonce__title {
  color: #fb431f;
  text-decoration: underline;
}

@media (max-width: 599.98px) {
  .list-anonce__item a {
    display: flex;
  }
}

@media (max-width: 440px) {
  .list-anonce__item a {
    flex-direction: column;
  }
  .list-anonce__item a .list-anonce__img {
    margin-bottom: 2rem;
  }
}

.list-anonce__item a.no_photo {
  position: relative;
  top: -7px;
}

@media (max-width: 1214.98px) {
  .list-anonce__item:nth-child(4), .list-anonce__item:nth-child(5) {
    display: none;
  }
}

.button-slider a {
  padding: 0;
}

.button-slider {
  margin-bottom: 20px;
}

.list-resource {
  flex: 1 1 auto;
  min-width: 0;
  padding-top: 25px;
  padding-bottom: 1.8rem;
  background-color: #faf1e5;
}

.list-resource__row {
  padding: 0;
  margin: 0;
}

.list-resource__row li {
  padding: 0;
  margin: 0;
}

.list-resource__row li::before {
  display: none;
}

.list-resource__item {
  position: relative;
  padding-top: 15px !important;
}

.list-resource__item a,
.list-resource__item a:hover {
  text-decoration: none;
  background-image: none;
}

.list-resource__item a:hover .list-resource__title {
  color: #fb431f;
  text-decoration: underline;
}

.list-resource__wrapper {
  position: relative;
  display: block;
  width: 126px;
  padding: 0 10px;
}

.list-resource__wrapper a {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
}

.list-resource__icon {
  position: absolute;
  top: -15px;
  display: block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.list-resource__icon_i1 {
  background-image: url("/desimages/link/icons_link1.svg");
}

.list-resource__wrapper a:hover .list-resource__icon_i1 {
  background-image: url("/desimages/link/icons_link1-hover.svg");
}

.list-resource__icon_i2 {
  background-image: url("/desimages/link/icons_link2.svg");
}

.list-resource__wrapper a:hover .list-resource__icon_i2 {
  background-image: url("/desimages/link/icons_link2-hover.svg");
}

.list-resource__icon_i3 {
  background-image: url("/desimages/link/icons_link3.svg");
}

.list-resource__wrapper a:hover .list-resource__icon_i3 {
  background-image: url("/desimages/link/icons_link3-hover.svg");
}

.list-resource__img {
  display: block;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.list-resource__title {
  display: block;
  font-size: 1.3rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #687c29;
  text-align: center;
}

.list-resource__row .slick-dots,
.button-slider .slick-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 440px) {
  .list-resource__row .slick-dots,
  .button-slider .slick-dots {
    justify-content: flex-start;
  }
}

.list-resource__row .slick-dots button,
.button-slider .slick-dots button {
  width: 130px;
  height: 2px;
  font-size: 0;
  background-color: #687c29;
  border: 1px solid transparent;
}

@media (max-width: 859.98px) {
  .list-resource__row .slick-dots button,
  .button-slider .slick-dots button {
    width: 40px;
  }
}

.list-resource__row .slick-dots button:hover,
.button-slider .slick-dots button:hover {
  background-color: #fb9b1f;
}

.list-resource__row .slick-dots li,
.button-slider .slick-dots li {
  margin-right: 10px;
}

.list-resource__row .slick-dots .slick-active button,
.button-slider .slick-dots .slick-active button {
  background-color: #fb9b1f;
}

.after_video {
  padding-top: 1.7rem;
  padding-bottom: 7rem;
  background-color: #fff;
}

.after_video .slick-slide img {
  display: block;
  min-width: 175px;
  margin: 0 auto;
}

.after_video .list-resource__img {
  margin-right: 0;
  margin-left: 0;
}

@media (max-width: 440px) {
  .after_video .list-resource__img {
    width: 100%;
  }
}

.media-tab {
  padding-top: 49px;
  padding-bottom: 50px;
}

.media-tab .active {
  display: block !important;
}

.media-tab .tab-pane {
  display: none;
}

.media-tab a {
  text-decoration: none;
}

.media-tab a:hover {
  text-decoration: none;
}

.media-tab ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.media-tab ul li {
  padding: 0;
  margin: 0;
}

.media-tab ul li::before {
  display: none;
}

@media (max-width: 599.98px) {
  #myTab {
    flex-direction: column;
    padding: 0 10px;
  }
}

#myTab > li {
  margin-right: 40px;
}

.media-tab .title-section__title {
  margin-right: 14px;
}

.media-tab .video {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.media-tab .video > div {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 599.98px) {
  .media-tab .video > div:last-child {
    display: none;
  }
  .media-tab .video > div:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.media-tab .nav {
  padding: 0 66px;
}

.photo-video-block-main {
  position: relative;
}

.photo-video-block-main a {
  display: block;
}

.list-inet {
  padding-top: 2.5rem;
  background-color: #faf1e5;
}

.list-inet__list {
  columns: 3;
}

@media (max-width: 1514.98px) {
  .list-inet__list {
    columns: 2;
  }
}

.list-inet__list li {
  max-width: 400px;
  padding-left: 3.4rem;
}

.list-inet__list li::before {
  top: 1.4rem;
  width: 26px;
  height: 2px;
  background-color: #fb9b1f;
}

.list-inet__list a {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  color: #353534;
}

.list-inet__list a:hover {
  color: #fb431f;
  text-decoration: underline;
}

.list-inet-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  color: #353534;
  text-transform: uppercase;
}

.list-inet__list-600-js.list-inet__list {
  columns: initial;
}

.list-inet__list-600-js .list-inet__list li {
  max-width: 100%;
}

.list-inet__list .slick-dots {
  position: absolute;
  padding: 0;
  margin: 0;
}

.list-inet__list .slick-dots li {
  padding: 0;
  margin: 0;
}

.list-inet__list .slick-dots li::before {
  display: none;
}

.list-inet__list .slick-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.list-inet__list .slick-dots button {
  width: 130px;
  height: 2px;
  font-size: 0;
  background-color: #687c29;
  border: 1px solid transparent;
}

@media (max-width: 859.98px) {
  .list-inet__list .slick-dots button {
    width: 78px;
  }
}

.list-inet__list .slick-dots button:hover {
  background-color: #fb9b1f;
}

.list-inet__list .slick-dots li {
  margin-left: 10px;
}

.list-inet__list .slick-dots .slick-active button {
  background-color: #fb9b1f;
}

@media (max-width: 440px) {
  .media-tab .title-section__all {
    display: none;
  }
}

.mobile-media-button {
  display: none;
}

@media (max-width: 440px) {
  .mobile-media-button {
    display: inline-block !important;
    margin-top: 30px;
  }
}

.block-4__row {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
}

.block-4__row li {
  padding: 0;
  margin: 0;
}

.block-4__row li::before {
  display: none;
}

.block-4__col {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 30px !important;
  padding-left: 30px !important;
  margin-bottom: 8.3rem !important;
}

@media (max-width: 1214.98px) {
  .block-4__col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 599.98px) {
  .block-4__col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.block-4__link {
  display: block;
  line-height: 1.3;
}

.block-4__link:hover {
  color: #fb431f;
}

.block-4__link:hover .block-4__title {
  color: #fb431f;
}

.block-4__img {
  display: block;
  margin-bottom: 1.2rem;
}

.block-4__title {
  display: block;
  font-size: 1.6rem;
  color: #353534;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.list-video-col,
.list-photo-col {
  position: relative;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  transition: all .2s;
}

.list-video-col:hover,
.list-photo-col:hover {
  background-color: #faf1e5;
}

.list-video-col .block-4__img {
  position: relative;
}

.list-video-col .block-4__img::before {
  position: absolute;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background-image: url("/desimages/icons_play.svg");
  opacity: .5;
  transition: all .2s;
}

.list-video-col .block-4__link:hover .block-4__img::before {
  opacity: 1;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

html.hc-nav-yscroll {
  overflow-y: scroll;
}

body.hc-nav-open {
  position: fixed;
  width: 100%;
  min-height: 100%;
  overflow: visible;
}

.hc-offcanvas-nav {
  position: fixed;
  top: 0;
  z-index: 9999;
  display: none;
  height: 100%;
  text-align: left;
  visibility: hidden;
}

.hc-offcanvas-nav.is-ios * {
  cursor: pointer !important;
}

.hc-offcanvas-nav .nav-container {
  position: fixed;
  top: 0;
  z-index: 9998;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transition: transform 0.4s ease;
}

.hc-offcanvas-nav .nav-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

.hc-offcanvas-nav .nav-content {
  height: 100%;
}

.hc-offcanvas-nav .nav-wrapper-0 > .nav-content {
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
}

.hc-offcanvas-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.hc-offcanvas-nav li {
  position: relative;
  display: block;
}

.hc-offcanvas-nav li.level-open > .nav-wrapper {
  visibility: visible;
}

.hc-offcanvas-nav li:not(.custom-content) a {
  position: relative;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
}

.hc-offcanvas-nav li:not(.custom-content) a[disabled] {
  cursor: not-allowed;
}

.hc-offcanvas-nav li:not(.custom-content) a, .hc-offcanvas-nav li:not(.custom-content) a:hover {
  text-decoration: none;
}

.hc-offcanvas-nav input[type="checkbox"] {
  display: none;
}

.hc-offcanvas-nav label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.hc-offcanvas-nav .nav-item-wrapper {
  position: relative;
}

.hc-offcanvas-nav .nav-item-link {
  position: relative;
  box-sizing: border-box;
  display: block;
}

.hc-offcanvas-nav:not(.user-is-tabbing) .nav-close-button:focus,
.hc-offcanvas-nav:not(.user-is-tabbing) .nav-item-wrapper a:focus {
  outline: none;
}

.hc-offcanvas-nav .nav-close:focus,
.hc-offcanvas-nav .nav-next:focus,
.hc-offcanvas-nav .nav-back:focus {
  z-index: 10;
}

.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  width: 100%;
  height: 100%;
  visibility: hidden;
  content: '';
  opacity: 0;
  transition: visibility 0s ease 0.4s, opacity 0.4s ease;
  overscroll-behavior: none;
}

.hc-offcanvas-nav.disable-body::after {
  position: fixed;
}

.hc-offcanvas-nav .nav-wrapper::after {
  position: absolute;
}

.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {
  visibility: visible;
  opacity: 1;
  transition-delay: .05s;
}

.hc-offcanvas-nav:not(.nav-open)::after {
  pointer-events: none;
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {
  display: none;
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0 {
  max-height: 100vh;
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0 > .nav-content {
  box-sizing: border-box;
  max-height: 100vh;
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
  min-width: 0;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: height 0s ease 0.4s;
}

.hc-offcanvas-nav.nav-levels-expand .level-open > .nav-wrapper {
  max-height: none;
  overflow: visible;
  visibility: visible;
}

.hc-offcanvas-nav.nav-levels-overlap .nav-content {
  box-sizing: border-box;
  max-height: 100vh;
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
}

.hc-offcanvas-nav.nav-levels-overlap .nav-wrapper {
  max-height: 100vh;
}

.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
  position: absolute;
  top: 0;
  z-index: 9999;
  height: 100%;
  visibility: hidden;
  transition: visibility 0s ease 0.4s, transform 0.4s ease;
}

.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
  position: static;
}

.hc-offcanvas-nav.nav-levels-overlap ul li.level-open > .nav-wrapper {
  visibility: visible;
  transition: transform 0.4s ease;
  transform: translate3d(0, 0, 0);
}

.hc-offcanvas-nav.nav-position-left {
  left: 0;
}

.hc-offcanvas-nav.nav-position-left .nav-container {
  left: 0;
}

.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.hc-offcanvas-nav.nav-position-right {
  right: 0;
}

.hc-offcanvas-nav.nav-position-right .nav-container {
  right: 0;
}

.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.hc-offcanvas-nav.nav-position-top {
  top: 0;
}

.hc-offcanvas-nav.nav-position-top .nav-container {
  top: 0;
  width: 100%;
}

.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {
  left: 0;
  transform: translate3d(0, -100%, 0);
}

.hc-offcanvas-nav.nav-position-bottom {
  top: auto;
  bottom: 0;
}

.hc-offcanvas-nav.nav-position-bottom .nav-container {
  top: auto;
  bottom: 0;
  width: 100%;
}

.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {
  left: 0;
  transform: translate3d(0, 100%, 0);
}

.hc-offcanvas-nav.nav-open[class*='hc-nav-'] div.nav-container {
  transform: translate3d(0, 0, 0);
}

.hc-offcanvas-nav.rtl {
  text-align: right;
  direction: rtl;
}

.hc-nav-trigger {
  position: absolute;
  top: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9980;
  display: none;
  width: 30px;
  min-height: 24px;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
}

.hc-nav-trigger span {
  top: 50%;
  width: 30px;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
}

.hc-nav-trigger span, .hc-nav-trigger span::before, .hc-nav-trigger span::after {
  position: absolute;
  left: 0;
  display: block;
  height: 4px;
  background: #34495e;
  transition: all 0.2s ease;
}

.hc-nav-trigger span::before, .hc-nav-trigger span::after {
  width: 100%;
  content: '';
}

.hc-nav-trigger span::before {
  top: -10px;
}

.hc-nav-trigger span::after {
  bottom: -10px;
}

.hc-nav-trigger.toggle-open span {
  background: rgba(0, 0, 0, 0);
  transform: rotate(45deg);
}

.hc-nav-trigger.toggle-open span::before {
  transform: translate3d(0, 10px, 0);
}

.hc-nav-trigger.toggle-open span::after {
  transform: rotate(-90deg) translate3d(10px, 0, 0);
}

.hc-offcanvas-nav {
  font-family: sans-serif;
  /* Left */
  /* Right */
  /* Top */
  /* Bottom */
  /* Expand & none */
  /* RTL */
}

.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {
  background: rgba(0, 0, 0, 0.3);
}

.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {
  background: #597c2a;
}

.hc-offcanvas-nav .nav-content > h2,
.hc-offcanvas-nav .nav-content > h3,
.hc-offcanvas-nav .nav-content > h4,
.hc-offcanvas-nav .nav-content > h5,
.hc-offcanvas-nav .nav-content > h6 {
  padding: 20px 17px;
  margin: 0;
  font-size: 19px;
  font-weight: normal;
  color: #fff;
}

.hc-offcanvas-nav .nav-content > h2:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content > h3:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content > h4:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content > h5:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content > h6:not(.nav-title):not(.level-title) {
  padding: 15px 17px;
  font-size: 16px;
  background: #597c2a;
}

.hc-offcanvas-nav .nav-item-link,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a {
  z-index: 1;
  padding: 10px 17px;
  font-size: 14px;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #597c2a;
  transition: background .1s ease;
}

.hc-offcanvas-nav .nav-item-link:focus, .hc-offcanvas-nav .nav-item-link:focus-within,
.hc-offcanvas-nav li.nav-close a:focus,
.hc-offcanvas-nav li.nav-close a:focus-within,
.hc-offcanvas-nav .nav-back a:focus,
.hc-offcanvas-nav .nav-back a:focus-within {
  z-index: 10;
}

.hc-offcanvas-nav .nav-item-link[disabled],
.hc-offcanvas-nav li.nav-close a[disabled],
.hc-offcanvas-nav .nav-back a[disabled] {
  color: rgba(255, 255, 255, 0.5);
}

.hc-offcanvas-nav div.nav-back + ul > li:first-child > .nav-item-wrapper > .nav-item-link {
  border-top: none !important;
}

.hc-offcanvas-nav:not(.touch-device) li:not(.nav-item-custom) a:not([disabled]):hover {
  background: #547427;
}

.hc-offcanvas-nav .nav-custom-content {
  padding: 14px 17px;
  font-size: 14px;
  border-bottom: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-highlight {
  background: #4e6d25;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > ul:first-of-type > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link {
  border-top: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > ul:first-of-type > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link + a {
  border-top: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > ul:not(:last-child) {
  border-bottom: 2px solid #597c2a;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > ul + h2,
.hc-offcanvas-nav .nav-wrapper > .nav-content > ul + h3,
.hc-offcanvas-nav .nav-wrapper > .nav-content > ul + h4,
.hc-offcanvas-nav .nav-wrapper > .nav-content > ul + h5,
.hc-offcanvas-nav .nav-wrapper > .nav-content > ul + h6 {
  margin-top: -2px;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > h2 + ul > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link,
.hc-offcanvas-nav .nav-wrapper > .nav-content > h3 + ul > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link,
.hc-offcanvas-nav .nav-wrapper > .nav-content > h4 + ul > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link,
.hc-offcanvas-nav .nav-wrapper > .nav-content > h5 + ul > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link,
.hc-offcanvas-nav .nav-wrapper > .nav-content > h6 + ul > li:first-child:not(.nav-back):not(.nav-close) > .nav-item-wrapper > .nav-item-link {
  border-top: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-title + h2,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-title + h3,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-title + h4,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-title + h5,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-title + h6,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .level-title + h2,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .level-title + h3,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .level-title + h4,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .level-title + h5,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .level-title + h6,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-close + h2,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-close + h3,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-close + h4,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-close + h5,
.hc-offcanvas-nav .nav-wrapper > .nav-content > .nav-close + h6 {
  border-top: 1px solid #597c2a;
}

.hc-offcanvas-nav li {
  color: #fff;
}

.hc-offcanvas-nav li.nav-parent .nav-item-link:last-child {
  padding-right: 58px;
}

.hc-offcanvas-nav li.nav-parent .nav-item-link:not(:last-child) {
  margin-right: 45px;
}

.hc-offcanvas-nav .nav-close-button span,
.hc-offcanvas-nav .nav-parent .nav-next,
.hc-offcanvas-nav .nav-back span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  text-align: center;
  cursor: pointer;
  transition: background .1s ease;
}

.hc-offcanvas-nav .nav-close-button {
  position: relative;
  display: block;
}

.hc-offcanvas-nav .nav-close-button span::before, .hc-offcanvas-nav .nav-close-button span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  content: '';
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

.hc-offcanvas-nav .nav-close-button span::before {
  margin-left: -23px;
  transform: rotate(135deg);
}

.hc-offcanvas-nav .nav-close-button span::after {
  transform: rotate(-45deg);
}

.hc-offcanvas-nav .nav-content > .nav-close {
  position: relative;
  z-index: 2;
}

.hc-offcanvas-nav .nav-content > .nav-close a {
  z-index: 1;
  box-sizing: border-box;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
}

.hc-offcanvas-nav .nav-content > .nav-close a:not(.has-label) {
  height: 50px;
}

.hc-offcanvas-nav .nav-content > .nav-close a.has-label {
  padding: 14px 17px;
  border-top: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-content > .nav-close a:hover {
  background: radial-gradient(farthest-corner at top right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  border: none;
}

.hc-offcanvas-nav .nav-content > .nav-close.has-label + ul {
  margin-top: -1px;
}

.hc-offcanvas-nav:not(.nav-close-button-empty) .nav-content > .nav-close {
  margin-bottom: -1px;
}

.hc-offcanvas-nav .nav-title + .nav-close a:not(.has-label) {
  position: absolute;
  top: -66px;
  right: 0;
  width: 45px;
  height: 66px;
  line-height: 66px;
}

.hc-offcanvas-nav.nav-close-button-empty .nav-title {
  padding-right: 55px;
  padding-left: 24px;
}

.hc-offcanvas-nav li.nav-close a:not(.has-label) {
  height: 49px;
}

.hc-offcanvas-nav .nav-content > .nav-close:first-child a,
.hc-offcanvas-nav .nav-title + .nav-close a.has-label,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a {
  background: #517126;
  border-top: 1px solid #597c2a;
  border-bottom: 1px solid #597c2a;
}

.hc-offcanvas-nav .nav-content > .nav-close:first-child a:hover,
.hc-offcanvas-nav .nav-title + .nav-close a.has-label:hover,
.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav .nav-back a:hover {
  background: #4d6c24;
}

.hc-offcanvas-nav li.nav-close:not(:first-child) a,
.hc-offcanvas-nav li.nav-back:not(:first-child) a {
  margin-top: -1px;
}

.hc-offcanvas-nav a.nav-next {
  border-bottom: 1px solid #597c2a;
  border-left: 1px solid transparent;
}

.hc-offcanvas-nav .nav-next span::before,
.hc-offcanvas-nav .nav-back span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  margin-left: -2px;
  content: '';
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform-origin: center;
}

.hc-offcanvas-nav .nav-next span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hc-offcanvas-nav .nav-next span::before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.hc-offcanvas-nav .nav-back span::before {
  margin-left: 2px;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.hc-offcanvas-nav.nav-position-left.nav-open.nav-levels-overlap .nav-wrapper {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
}

.hc-offcanvas-nav.nav-position-right.nav-open.nav-levels-overlap .nav-wrapper {
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
}

.hc-offcanvas-nav.nav-position-right .nav-next span::before {
  margin-left: 2px;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.hc-offcanvas-nav.nav-position-right .nav-back span::before {
  margin-left: -2px;
  transform: translate(-50%, -50%) rotate(135deg);
}

.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.hc-offcanvas-nav.nav-position-top .nav-next span::before {
  margin-right: -2px;
  margin-left: 0;
  transform: translate(-50%, -50%) rotate(-135deg);
}

.hc-offcanvas-nav.nav-position-top .nav-back span::before {
  margin-right: -2px;
  margin-left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper {
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
}

.hc-offcanvas-nav.nav-position-bottom .nav-next span::before {
  margin-right: -2px;
  margin-left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.hc-offcanvas-nav.nav-position-bottom .nav-back span::before {
  margin-right: -2px;
  margin-left: 0;
  transform: translate(-50%, -50%) rotate(-135deg);
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper, .hc-offcanvas-nav.nav-levels-none ul .nav-wrapper {
  background: transparent;
  box-shadow: none;
}

.hc-offcanvas-nav.nav-levels-expand li.level-open, .hc-offcanvas-nav.nav-levels-none li.level-open {
  background: #4e6d25;
}

.hc-offcanvas-nav.nav-levels-expand li.level-open > .nav-item-wrapper > a, .hc-offcanvas-nav.nav-levels-none li.level-open > .nav-item-wrapper > a {
  border-bottom: 1px solid #597c2a;
}

.hc-offcanvas-nav.nav-levels-expand li.level-open > .nav-item-wrapper > a:hover, .hc-offcanvas-nav.nav-levels-none li.level-open > .nav-item-wrapper > a:hover {
  background: #517126;
}

.hc-offcanvas-nav.nav-levels-expand li.level-open > .nav-item-wrapper > .nav-next span::before,
.hc-offcanvas-nav.nav-levels-expand li.level-open > .nav-item-wrapper > a > .nav-next span::before, .hc-offcanvas-nav.nav-levels-none li.level-open > .nav-item-wrapper > .nav-next span::before,
.hc-offcanvas-nav.nav-levels-none li.level-open > .nav-item-wrapper > a > .nav-next span::before {
  margin-top: -2px;
  transform: translate(-50%, -50%) rotate(-135deg);
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-1 .nav-item-link, .hc-offcanvas-nav.nav-levels-none .nav-wrapper-1 .nav-item-link {
  padding-left: calc(17px + 20px * 1);
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-2 .nav-item-link, .hc-offcanvas-nav.nav-levels-none .nav-wrapper-2 .nav-item-link {
  padding-left: calc(17px + 20px * 2);
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-3 .nav-item-link, .hc-offcanvas-nav.nav-levels-none .nav-wrapper-3 .nav-item-link {
  padding-left: calc(17px + 20px * 3);
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-4 .nav-item-link, .hc-offcanvas-nav.nav-levels-none .nav-wrapper-4 .nav-item-link {
  padding-left: calc(17px + 20px * 4);
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-5 .nav-item-link, .hc-offcanvas-nav.nav-levels-none .nav-wrapper-5 .nav-item-link {
  padding-left: calc(17px + 20px * 5);
}

.hc-offcanvas-nav.rtl a.nav-next {
  border-right: 1px solid #496522;
  border-left: none;
}

.hc-offcanvas-nav.rtl .nav-title + .nav-close a:not(.has-label),
.hc-offcanvas-nav.rtl .nav-close-button span,
.hc-offcanvas-nav.rtl .nav-next,
.hc-offcanvas-nav.rtl .nav-back span {
  right: auto;
  left: 0;
}

.hc-offcanvas-nav.rtl li.nav-parent .nav-item-link:last-child {
  padding-right: 17px;
  padding-left: 58px;
}

.hc-offcanvas-nav.rtl li.nav-parent .nav-item-link:not(:last-child) {
  margin-right: 0;
  margin-left: 45px;
}

.hc-offcanvas-nav.rtl .nav-wrapper-1 li.nav-item .nav-item-link {
  padding-right: calc(17px + 20px * 1);
}

.hc-offcanvas-nav.rtl .nav-wrapper-2 li.nav-item .nav-item-link {
  padding-right: calc(17px + 20px * 2);
}

.hc-offcanvas-nav.rtl .nav-wrapper-3 li.nav-item .nav-item-link {
  padding-right: calc(17px + 20px * 3);
}

.hc-offcanvas-nav.rtl .nav-wrapper-4 li.nav-item .nav-item-link {
  padding-right: calc(17px + 20px * 4);
}

.hc-offcanvas-nav.rtl .nav-wrapper-5 li.nav-item .nav-item-link {
  padding-right: calc(17px + 20px * 5);
}

.hc-offcanvas-nav .nav-close-button span::before, .hc-offcanvas-nav .nav-close-button span::after {
  border-color: #fff;
}

.mobile-from-horizont,
.mobile-from-left {
  padding: 0;
  margin: 0;
  border-top: 1px solid #71941c;
  border-bottom: none !important;
}

.mobile-from-horizont li,
.mobile-from-left li {
  padding: 0;
  margin: 0;
}

.mobile-from-horizont li::before,
.mobile-from-left li::before {
  display: none;
}

.mobile-from-horizont a,
.mobile-from-left a {
  border-top: none !important;
  border-bottom: none !important;
}

.mobile-from-horizont {
  padding-bottom: 20px !important;
  border-top: none !important;
}

.mobile-from-left {
  padding-top: 20px !important;
}

.nav-item-wrapper::before {
  position: absolute;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
  width: 22px;
  height: 1px;
  background-color: #fb9b1f;
}

.hc-offcanvas-nav .nav-item-link,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a {
  padding-left: 30px;
}

.nav-title {
  font-weight: bold !important;
}

.menu-item-search {
  padding-bottom: 20px !important;
  border-bottom: 1px solid #71941c;
}

.menu-item-search .nav-item-wrapper::before {
  display: none;
}

.menu-item-search .nav-item-link {
  padding: 0;
}

.menu-item-search .block-search {
  padding: 0;
  padding: 20px 10px;
  background-color: #4f6614;
}

.mobile-menu-social .nav-item-wrapper::before {
  display: none;
}

.mobile-menu-social {
  display: flex;
  align-items: center;
  background-color: #4f6614 !important;
}

.mobile-menu-social a {
  min-height: auto;
  padding: 5px !important;
  border-bottom: none !important;
}

.vsv-mobile {
  padding: 10px !important;
  margin-left: auto !important;
  background-color: #243200;
}

.vsv-mobile img {
  min-width: 64px;
}
