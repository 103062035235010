//пагинация

@mixin pages {
    .hv_pages,
    .pages{
        display: flex;
        padding-top: 2rem;
        margin-top: 8px;
        border-top: 1px solid #587c29;

        @include media-breakpoint-down(md){
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(xs){
            margin-top: 15px;
        }
    }

    .opinion-list-inner .hv_pages{
        margin-top: 30px;
    }

    .hv_page_sel,
    .hv_pages_item a,
    .page_item,
    .p_next_block,
    .p_prev_block{
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        min-width: 26px;
        height: 26px;
        padding: 10px;
        margin-right: 1rem;
        font-size: 1.6rem;
        line-height: 1;
        color: #587c29;
        text-align: center;
        text-decoration: none;
        border-radius: 100%;
        @include font-bold;

        &:hover{
            color: #fff;
            text-decoration: none;
            background-color: $color-hover;
        }
    }

    .p_next,
    .p_prev{
        width: auto;
        border-radius: 0;
    }

    .p_prev{
        padding-left: 0;
        padding-left: 10px;
        margin-left: -10px;
    }

    .hv_page_sel,
    .page_item--selected,
    .page_item_selected {
        width: 26px;
        color: #fff;
        background-color: #fb9b1f;

        &:hover{
            color: #000;
            text-decoration: none;
        }
    }

    // .p_prev{
    //     background-image: url('/desimages/pagination_prev.png');
    //     background-repeat: no-repeat;
    //     background-position: center;
    // }

    // .p_next {
    //     background-image: url('/desimages/pagination_next.png');
    //     background-repeat: no-repeat;
    //     background-position: center;
    // }

}

@include pages;

