@mixin social {
    .social{
        display: flex;
        @include reset-ul;

        img{
            min-width: 26px;
            max-width: initial;
        }
    }

    .social li{

    }

    .social a{
        display: flex;
        align-items: center;
        min-height: 54px;
        padding: 0 6px;

        &:hover{
            background-color: #81a53a;
        }
    }

    .social.vsv img{
        min-width: 44px;
    }
}

@include social;

