@mixin list-anonce {
    .list-anonce__row{
        @include reset-ul;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include media-breakpoint-down(xs){
            flex-direction: column;
        }
    }

    .list-anonce__item{
        @include make-col-ready(0);
        width: 242px;
        border-left: 4px solid #fb9b1f;

        @include media-breakpoint-down(xs){
            width: 100%;
            margin-bottom: 30px !important;
        }

        a{

            display: block;
            padding: 0 12px;
            text-decoration: none;

            span {
                display: block;
            }

            &:hover{
                text-decoration: none;
            }

            &:hover .list-anonce__title{
                color: $color-hover;
                text-decoration: underline;
            }

            @include media-breakpoint-down(xs){
                display: flex;
            }

            @media (max-width: 440px) {
                flex-direction: column;

                .list-anonce__img{
                    margin-bottom: 2rem;
                }
            }
        }

        a.no_photo{
            position: relative;
            top: -7px;
        }

        @include media-breakpoint-down(lg){
            &:nth-child(4),
            &:nth-child(5){
                display: none;
            }
        }

    }

    .button-slider a{
        padding: 0;
    }

    .button-slider{
        margin-bottom: 20px;
    }
}

.list-anonce__img{
    display: block;
    width: 100%;
    max-width: 176px;
    padding-bottom: 1.5rem;

    @include media-breakpoint-down(xs){
        padding-bottom: 0;
        margin-right: 16px;
    }

    img{
        display: block;
    }
}

.list-anonce__title{
    @include font-bold;
    display: block;
    font-size: 1.6rem;
    color: #353534;
}

//моды
.list-anonce--smi{
    a{
        padding: 0;
    }

    .list-anonce__item{
        border: none;
    }

    .list-anonce__img{
        max-width: 100%;
    }

    .list-anonce__title{
        @include font-regular;
        margin-bottom: 6px;
    }

}

@include list-anonce()
