$hc-offcanvas-nav-transition-duration: .4s !default;
$hc-offcanvas-nav-transition-timing-function: ease !default;

html.hc-nav-yscroll {
    overflow-y: scroll;
}

body.hc-nav-open {
    position: fixed;
    width: 100%;
    min-height: 100%;
    overflow: visible;
}

.hc-offcanvas-nav {
    position: fixed;
    top: 0;
    z-index: 9999;
    display: none;
    height: 100%;
    text-align: left;
    visibility: hidden;

    &.is-ios {
        * {
            cursor: pointer !important;
        }
    }

    .nav-container {
        position: fixed;
        top: 0;
        z-index: 9998;
        box-sizing: border-box;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        transition: transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
    }

    .nav-wrapper {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overscroll-behavior: none;
    }

    .nav-content {
        height: 100%;
    }

    .nav-wrapper-0 {
        & > .nav-content {
            box-sizing: border-box;
            overflow: scroll;
            overflow-x: visible;
            overflow-y: auto;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        position: relative;
        display: block;

        &.level-open {
            & > .nav-wrapper {
                visibility: visible;
            }
        }

        &:not(.custom-content) {
            a {
                position: relative;
                box-sizing: border-box;
                display: block;
                cursor: pointer;

                &[disabled] {
                    cursor: not-allowed;
                }

                &,
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    input[type="checkbox"] {
        display: none;
    }

    label {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        cursor: pointer;
    }

    .nav-item-wrapper {
        position: relative;
    }

    .nav-item-link {
        position: relative;
        box-sizing: border-box;
        display: block;
    }

    &:not(.user-is-tabbing) {
        .nav-close-button,
        .nav-item-wrapper a {
            &:focus {
                outline: none;
            }
        }
    }

    .nav-close,
    .nav-next,
    .nav-back {
        &:focus {
            z-index: 10;
        }
    }

    // Background overlay

    &.disable-body,
    .nav-wrapper {
        &::after {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9990;
            width: 100%;
            height: 100%;
            visibility: hidden;
            content: '';
            opacity: 0;
            transition: visibility 0s ease $hc-offcanvas-nav-transition-duration, opacity $hc-offcanvas-nav-transition-duration ease;
            overscroll-behavior: none;
        }
    }

    &.disable-body {
        &::after {
            position: fixed;
        }
    }

    .nav-wrapper {
        &::after {
            position: absolute;
        }
    }

    &.disable-body.nav-open,
    .sub-level-open {
        &::after {
            visibility: visible;
            opacity: 1;
            transition-delay: .05s;
        }
    }

    &:not(.nav-open) {
        &::after {
            pointer-events: none;
        }
    }

    // Expand Levels
    &.nav-levels-expand {
        .nav-wrapper {
            &::after {
                display: none;
            }

            &.nav-wrapper-0 {
                max-height: 100vh;

                & > .nav-content {
                    box-sizing: border-box;
                    max-height: 100vh;
                    overflow: scroll;
                    overflow-x: visible;
                    overflow-y: auto;
                }
            }
        }

        ul {
            .nav-wrapper {
                min-width: 0;
                max-height: 0;
                overflow: hidden;
                visibility: hidden;
                transition: height 0s ease $hc-offcanvas-nav-transition-duration;
            }
        }

        .level-open {
            & > .nav-wrapper {
                max-height: none;
                overflow: visible;
                visibility: visible;
            }
        }
    }

    // Transform Levels
    &.nav-levels-overlap {
        .nav-content {
            box-sizing: border-box;
            max-height: 100vh;
            overflow: scroll;
            overflow-x: visible;
            overflow-y: auto;
        }

        .nav-wrapper {
            max-height: 100vh;
        }

        ul {
            .nav-wrapper {
                position: absolute;
                top: 0;
                z-index: 9999;
                height: 100%;
                visibility: hidden;
                transition: visibility 0s ease $hc-offcanvas-nav-transition-duration, transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
            }

            li {
                &.nav-parent {
                    position: static;
                }

                &.level-open {
                    & > .nav-wrapper {
                        visibility: visible;
                        transition: transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
        }
    }

    // Left
    &.nav-position-left {
        left: 0;

        .nav-container {
            left: 0;
        }

        &.nav-levels-overlap {
            li {
                .nav-wrapper {
                    left: 0;
                    transform: translate3d(-100%, 0, 0);
                }
            }
        }
    }

    // Right
    &.nav-position-right {
        right: 0;

        .nav-container {
            right: 0;
        }

        &.nav-levels-overlap {
            li {
                .nav-wrapper {
                    right: 0;
                    transform: translate3d(100%, 0, 0);
                }
            }
        }
    }

    // Top
    &.nav-position-top {
        top: 0;

        .nav-container {
            top: 0;
            width: 100%;
        }

        &.nav-levels-overlap {
            li {
                .nav-wrapper {
                    left: 0;
                    transform: translate3d(0, -100%, 0);
                }
            }
        }
    }

    // Bottom
    &.nav-position-bottom {
        top: auto;
        bottom: 0;

        .nav-container {
            top: auto;
            bottom: 0;
            width: 100%;
        }

        &.nav-levels-overlap {
            li {
                .nav-wrapper {
                    left: 0;
                    transform: translate3d(0, 100%, 0);
                }
            }
        }
    }

    // Open Nav
    &.nav-open[class*='hc-nav-'] {
        div.nav-container {
            transform: translate3d(0, 0, 0);
        }
    }

    // RTL
    &.rtl {
        text-align: right;
        direction: rtl;
    }
}
