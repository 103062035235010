@mixin left-menu {
    .menu-arrow{
        padding-right: .3rem;
        padding-left: 1.2rem;
        color: #fff;
        cursor: pointer;

        img{
            max-width: initial;
        }
    }

    .left-menu{
        @include reset-ul;

    }

    .li-row{
        display: flex;
        align-items: center;
        padding-top: 7px;
        padding-right: 1.5rem;
        padding-bottom: 7px;
        padding-left: 7.5rem;
    }

    .left-menu li{
        $top_psevdo: 1.7rem;

        position: relative;


        &:hover{
            background-color: #81a52a;
        }

        &:hover::before,
        &:hover::after{
            background-color: #fff;
        }

        &::before{
            @include pseudo();
            top: $top_psevdo;
            // z-index: 2;
            width: 4.4rem;
            height: auto;
            padding-top: 1px;
            background-color: #fb9b1f;
            border-radius: 0;
            transform: none;
        }

        &::after{
            @include pseudo();
            top: $top_psevdo;
            left: 5.4rem;
            // z-index: 2;
            width: .6rem;
            height: auto;
            padding-top: 1px;
            background-color: #fb9b1f;
            border-radius: 0;
        }
    }

    li.left-menu-open-js{
        background-color: #81a52a;
    }

    li.left-menu-open-js::after,
    li.left-menu-open-js::before{
        background-color: #fff;
    }

    .left-menu a{
        font-size: 1.4rem;
        color: #fff;
        @include font-bold();

        &:hover{
            text-decoration: none;
        }
    }

    //2lvl
    .left-menu ul li::before{
        display: none;
    }

    .left-menu ul li::after{
        background-color: #fff;
    }

    .left-menu ul .li-row:hover{
        background-color: #587c29;
    }
    //3lvl
    .left-menu ul ul li::before,
    .left-menu ul ul li::after{
        display: none;
    }

    .left-menu ul ul .li-row{
        padding-left: 88px;
    }

    //4lvl
    .left-menu ul ul ul .li-row{
        padding-left: 106px;
    }

    //изначально подменю скрыты
    .left-menu ul{
        display: none;
    }

    .left-menu-open-js > ul{
        display: initial;
    }

    .selected888{
        @include font-bold();
    }

    .li-row + ul a{
        @include font-regular;
    }


}

@include left-menu()
