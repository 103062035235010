// одна новость
.inner-news__share-ico{
    // position: relative;
    // width: 6rem;
    // height: 6rem;
    // margin-right: 10px;
    // cursor: pointer;
    // background-image: url("/desimages/share.svg");
    // background-repeat: no-repeat;
    // background-position: left center;
}

.inner-list-news__print a{
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    font-size: 0;
    background-image: url("/desimages/print.svg");
    background-repeat: no-repeat;
    background-position: left center;
}

.inner-news__row{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @include media-breakpoint-down(xs){
        flex-direction: column;
    }
}

.inner-news__content{

}

.inner-news__share{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 90px;

    @include media-breakpoint-down(xs){
        margin-top: 30px;
    }
}

.inner-news__date{
    margin-bottom: 1.4rem;
}

.inner-news__image{
    margin-bottom: 4rem;
}

// теги
.inner-news__tags{
    margin-top: 3.5rem;

    ul{
        display: flex;
    }

    li{
        padding: 0;
        margin: 0;
        margin-right: 20px;
    }

    a{
        @include font-bold;
        font-size: 1.6rem;
        color: #6d6c6c;
    }

    li::before{
        display: none;

    }
}


//фотогаллерея

.photo-list .inner-list-news__image a{
    display: block;
}


.photo-list .inner-list-news__row{
    flex-direction: column;
}

.photo-list .inner-list-news__image{
    max-width: 333px;
    margin: 0;

    span{
        display: block;
        margin-top: 1.4rem;
        font-family: $font-family-bold;
        font-size: 1.8rem;
    }
}

.photo-list .inner-list-news__item{
    @include make-col-ready(60px);
    @include make-col(4);

    &:nth-child(1) .inner-list-news__row,
    &:nth-child(2) .inner-list-news__row,
    &:nth-child(3) .inner-list-news__row{
        padding-top: 0;
        border-top: none;
    }
}

.photo-list .inner-list-news__text:hover{
    text-decoration: none;
}

.photo-list .inner-list-news__text{
    margin-top: 2px;
}

.photo-list .custom-date{
    margin-top: 0;
}
