@mixin obr {

    $color-link: #587c29;
    $color-link-hover: $color-hover;

    .red{
        color: $color-hover;
    }

    .fb_form_no{
        margin-bottom: 10px;
        color: $color-hover;
    }

    .fb_form_ok{
        margin-bottom: 10px;
        color: green;
    }

    .fb_form{
        max-width: 1045px;
        padding: 4.2rem 78px 4rem 28px;
        margin-bottom: 10rem;
        background-color: #faf1e5;
    }

    .fb_form__row{
        display: flex;
        margin-bottom: 2.7rem;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        & > label{
            flex-shrink: 0;
            width: 288px;
            padding-right: 1rem;
            font-size: 1.4rem;
        }
    }

    .fb_form .el_input{
        width: 100%;
    }

    .fb_form .el_input select,
    .fb_form .el_input textarea,
    .fb_form .el_input input[type=text]{
        display: block;
        width: 100%;
        max-width: 670px;
        height: 3rem;
        padding: 10px 16px;
        background-color: $color-white;
        border: none;

        &:focus{
            box-shadow: inset 0 0 0 2px rgba(74, 126, 255, .4);
        }
    }

    .fb_form input[type=submit]{
        margin-top: 6rem;
        margin-bottom: 0;

        &:hover{
            background-color: $color-hover;
        }
    }

    .fb_form .el_input textarea{
        min-height: 144px;
    }

    .fb_form .el_input .fill-field,
    .fb_form .for_code .fill-field{
        display: none;
    }

    .fb_form__text-required{
        margin-bottom: 5px;
        font-size: 1.4rem;
    }

    .optional-text-after{
        font-size: 1.4rem;
    }

    .optional-text-before {
        margin-bottom: 5rem;

        a{
            color: $color-link;

            &:hover{
                color: $color-link-hover;
            }
        }
    }

    .fb_form .sub_title{
        max-width: 677px;
    }

    .capcha-iframe-wrapper{
        display: flex;

        @include media-breakpoint-down(xs){
            flex-direction: column;
        }
    }

    .capcha-iframe-wrapper__image{
        flex-shrink: 0;
        width: 174px;

        a{
            font-size: 1.4rem;
        }
    }

    .capcha-iframe-wrapper__input{
        flex-shrink: 0;
        width: 166px;
    }

    .obr__form{
        width: 100%;
        padding: 30px 76px 11px 26px;
        margin-top: 26px;
        font-size: 19px;
        background-color: #f5f1ed;
        border: 1px solid #d9d5cf;

        @include media-breakpoint-down(lg){
            max-width: 100%;
        }

        @include media-breakpoint-down(md){
            padding: 20px 20px;
        }
    }

    .obr__field-required{
        margin-bottom: 10px;
        font-weight: bold;
    }

    .obr__field-required sup{
        color: $color-hover;
    }

    .obr__form label,
    .obr__attach-file-title,
    .digits_title,
    .psevdo-label{
        @include column-fix(250px);
        padding-top: 5px;
        margin-right: 30px;
        line-height: 1;
        color: #919191;

        @include media-breakpoint-down(md){
            flex-basis: 100%;
            margin-bottom: 10px;
        }
    }

    .obr__form sup{
        top: -.1em;
        color: $color-hover;
    }

    .obr__form > div{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;

        @include media-breakpoint-down(md){
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .obr__form input:not([type=submit]),
    .obr__form textarea,
    .obr__form select,
    .obr__file,
    .obr__wrrapper-code-block,
    .search-date-restrict{
        @include column-grow();
        width: 100%;
        padding: 2px 15px;
        color: #919191;
        background-color: #fff;
        border: 1px solid transparent;
    }

    .obr__form textarea{
        flex-basis: 150px;
    }

    .obr__form .el_obr_fill_field{
        display: none;
        width: 100%;
        margin-top: 5px;
        margin-left: 280px;
        color: $color-hover;
        visibility: visible !important;

        @include media-breakpoint-down(md){
            margin-left: 0;
        }

    }

    .obr__form input[type=file]{
        display: none;
    }

    .obr__file{
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        border: none;

        @include media-breakpoint-down(xs){
            flex-direction: column-reverse;
            padding: 0;
        }
    }

    .obr__file .el_obr_fill_field,
    .obr__result .el_obr_fill_field{
        margin-left: 0;
    }

    .obr__descript{
        width: 350px;
        margin-left: 15px;
        color: #919191;

        & > * {
            overflow-wrap: break-word;
        }

        @include media-breakpoint-down(xs){
            width: 100%;
            margin-bottom: 30px;
            margin-left: 0;
        }
    }

    .obr__form .obr__attach{
        flex-wrap: nowrap;
        align-items: flex-start !important;

        @include media-breakpoint-down(xs){
            margin-bottom: 0;
        }
    }

    .obr__searсh{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 3.2rem;
        padding: 0 16px 0 16px;
        margin-bottom: 15px;
        font-size: 1.6rem;
        color: $color-white;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 4px;
    }

    .attach-file-line{
        flex-shrink: 0;
        width: 150px;

        @include media-breakpoint-down(xs){
            margin-top: 30px;
        }
    }

    .attach-file-line input[type=file]{
        display: none;
    }

    .attach-file-line__close{
        display: inline-block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
        background-image: url("/images/design/icons_close.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 20px 20px;
    }

    .attach-file-line__wrapper{
        display: flex;
        align-items: center;
    }

    .attach-file-line__selected,
    .js-no-selected{
        font-size: 1.4rem;
    }

    .attached__row{
        display: flex;

        @include media-breakpoint-down(xs){
            flex-direction: column-reverse;
        }
    }

    .attached__text{
        padding-left: 10px;
        font-size: 1.4rem;
    }

    .obr__wrrapper-code-block{
        display: flex;
        background-color: transparent;
        border: none;
    }

    .obr__cap a{
        display: block;
        color: $color-hover;
    }

    .obr__submit{
        display: flex;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 0 !important;

        @include media-breakpoint-down(md){
            flex-direction: row !important;
            justify-content: center !important;
        }
    }

    .text_after{
        max-width: 895px;
        margin-top: 30px;
        margin-bottom: 25px !important;
    }

    .attach-file-line__block-name{
        display: none;
    }

    .obr__attach-file-title{
        flex-shrink: 0;
        @include media-breakpoint-down(xs){
            margin-bottom: 16px;
            font-weight: bold;
        }
    }


    #field_16{
        flex-basis: 55px;
        min-height: 1px !important;
    }


    .obr__code-box,
    .obr__submit{
        width: 100%;
    }

    .obr__code-box{
        display: flex;
    }


}

@include obr();


//****страница формы поиска****./
.search-date-restrict{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;


    > input {
        max-width: 200px;
    }

    .left{
        display: inline-block;
        padding: 0 1rem;
    }

    .well{
        position: relative;
    }

    .well img{
        @include centerer();
        right: 10px;
        cursor: pointer;
    }

    .well input{
        padding-top: 8px;
    }

    &_sort{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & input{
            flex-basis: auto !important;
            flex-grow: 1 !important;
            width: auto !important;
        }
    }

}

#ext-search{
    display: none;
    margin-bottom: 30px;
}

.ui-datepicker{
    max-width: 255px !important;
}

.ui-datepicker,
.ui-datepicker .ui-datepicker-title select{
    border-color: #4a7eff !important;
}

.ui-datepicker select.ui-datepicker-year{
    color: #4a7eff !important;
}

.ui-datepicker td a,
.ui-datepicker th,
.ui-datepicker select.ui-datepicker-year,
.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year,
.ui-datepicker td span {
    font-size: 19px !important;
}

.ui-datepicker .ui-datepicker-month{
    font-size: 17px !important; //чтобы в одну строку
}


.ui-datepicker .ui-datepicker-today a,
.ui-datepicker-today span{
    background-color: #4a7eff !important;
}


.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    position: absolute;
    top: 64%;
    left: 50%;
    display: block;
    margin-top: -8px;
    margin-left: -8px;
}

.ui-widget-header .ui-icon {
    background-image: url("/desimages/calendar/ui-icons_222222_256x240.png");
}

.ui-icon-circle-triangle-e {
    background-position: -48px -192px;
}

.ui-icon-circle-triangle-w {
    background-position: -80px -192px;
}

.ui-icon {
    display: block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    text-indent: -99999px;
    background-repeat: no-repeat;
}

.ui-datepicker td a:hover {
    color: #121212;
    text-decoration: underline;
    background-color: #feac49;
}
