@mixin list-resource{
    .list-resource{
        flex: 1 1 auto;
        min-width: 0;
        padding-top: 25px;
        padding-bottom: 1.8rem;
        background-color: #faf1e5;
    }

    .list-resource__row{
        @include reset-ul();
    }

    .list-resource__item{
        position: relative;
        padding-top: 15px !important;

        a,
        a:hover{
            text-decoration: none;
            background-image: none;
        }

        a:hover .list-resource__title{
            color: $color-hover;
            text-decoration: underline;
        }
    }

    .list-resource__wrapper{
        position: relative;
        display: block;
        width: 126px;
        padding: 0 10px;

        a{
            position: relative;
            display: block;
            padding: 0;
            margin: 0;
        }
    }

    .list-resource__icon{
        position: absolute;
        top: -15px;
        display: block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        @include centerer(true, false);
    }

    .list-resource__icon_i1{
        background-image: url("/desimages/link/icons_link1.svg");

        .list-resource__wrapper a:hover &{
            background-image: url("/desimages/link/icons_link1-hover.svg");
        }
    }

    .list-resource__icon_i2{
        background-image: url("/desimages/link/icons_link2.svg");

        .list-resource__wrapper a:hover &{
            background-image: url("/desimages/link/icons_link2-hover.svg");
        }
    }

    .list-resource__icon_i3{
        background-image: url("/desimages/link/icons_link3.svg");

        .list-resource__wrapper a:hover &{
            background-image: url("/desimages/link/icons_link3-hover.svg");
        }
    }

    .list-resource__img{
        display: block;
        width: 100px;
        margin: 0 auto;
        margin-bottom: 1rem;
    }

    .list-resource__title{
        display: block;
        font-size: 1.3rem;
        @include font-bold;
        color: #687c29;
        text-align: center;
    }

    // слайдер
    //button-slider нужен для стилизации dots
    .list-resource__row .slick-dots,
    .button-slider .slick-dots{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 440px) {
            justify-content: flex-start;
        }

        button{
            width: 130px;
            height: 2px;
            font-size: 0;
            background-color: #687c29;
            border: 1px solid transparent;

            @include media-breakpoint-down(sm){
                width: 40px;
            }

            &:hover{
                background-color: #fb9b1f;
            }
        }

        li {
            margin-right: 10px;
        }

        .slick-active button{
            background-color: #fb9b1f;
        }
    }


    //баннеры после видео

    .after_video{
        padding-top: 1.7rem;
        padding-bottom: 7rem;
        background-color: $color-white;
    }

    .after_video .slick-slide img{
        display: block;
        min-width: 175px;
        margin: 0 auto;
    }

    .after_video .list-resource__img{

        margin-right: 0;
        margin-left: 0;

        @media (max-width: 440px) {
            width: 100%;
        }

    }
}

@include list-resource()

