@mixin list_news {
    .list-news{
        padding-bottom: 71px;
        background-color: #faf1e5;

        @include media-breakpoint-down(sm){
            padding-bottom: 30px;
        }
    }

    .list-news__row{
        display: flex;
        @include reset-ul;
    }

    .list-news .list-news__row{
        @include media-breakpoint-down(sm){
            flex-direction: column;

            .list-news__item{
                padding-left: 0;
            }
        }
    }

    .list-news__row-big{
        @include reset-ul;

        @include media-breakpoint-down(xl){
            max-width: 435px;

            img{
                max-width: 435px;
            }
        }

        @include media-breakpoint-down(sm){
            max-width: 100%;
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(sm){
            a{
                max-width: 100%;
            }

            img{
                max-width: 100%;
            }
        }
    }

    .list-news__row-small{
        display: flex;
        @include reset-ul;

        @include media-breakpoint-down(xl){
            flex-direction: column;
        }
    }

    .list-news__row-small .list-news__item a{
        @include media-breakpoint-down(xl){
            display: flex;
            align-items: flex-start;
        }

        @media (max-width: 440px) {
            flex-direction: column;
        }

        img{
            @media (max-width: 440px) {
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    .list-news__row-small .list-news__item{
        @include media-breakpoint-down(xl){
            flex-basis: 0;
            padding-left: 26px;
            margin-bottom: 35px;
            margin-left: 0;
        }

        @include media-breakpoint-down(sm){
            margin-left: 0;
        }

        @media (max-width: 440px) {
            padding-left: 0;
        }
    }

    .list-news__row-small .list-news__item img{
        @include media-breakpoint-down(xl){
            position: relative;
            max-width: 150px;
            margin-right: 20px;
        }
    }

    // big *************************
    .list-news__item-big{
        flex-shrink: 0;
        width: 550px;

        @include media-breakpoint-down(xl){
            width: auto;
        }

        &:hover .list-news__name-big{
            margin-right: 2.6rem;
            text-decoration: underline;

            @media (max-width: 440px) {
                color: $color-hover;
            }
        }

        &:hover .gl-date{
            text-decoration: none;
        }

        a{
            position: relative;
            display: flex;
            flex-direction: column;

            &:hover{
                @media (max-width: 440px) {
                    text-decoration: none;
                }
            }
        }
    }

    .list-news__content{
        position: absolute;
        bottom: 14px;
        left: 0;
        width: 100%;
        padding: 0 20px;

        @include media-breakpoint-down(xl){
            position: relative;
            bottom: 0;
            padding-left: 0;

            & > span{
                color: $color-black;
            }

            & > .gl-date{
                color: #a3a3a3;
            }
        }

        @include media-breakpoint-down(sm){
            position: absolute;
            // top: initial;
            bottom: 14px;
            // left: initial;
            padding-left: 5px;

            & > span{
                color: $color-white;
            }

            & > .gl-date{
                color: $color-white;
            }

        }

        @media (max-width: 440px) {
            position: relative;
            bottom: 0;

            .list-news__name-big{
                color: $color-black;
            }

            .gl-date{
                color: #a3a3a3;
            }
        }
    }


    .list-news__name-big{
        display: block;
        font-size: 2.4rem;
        color: $color-white;
        text-transform: uppercase;
        @include font-bold;
    }

    // small *******************
    li.list-news__item{
        flex-basis: 274px;
        flex-grow: 1;
        width: 100%;
        margin-left: 26px;

        a{
            display: block;
            color: #353534;

            &:hover{
                color: $color-hover;
                text-decoration: none;
            }

            &:hover .list-news__name-small{
                color: $color-hover;
                text-decoration: underline;
            }
        }

        img{
            display: block;
        }

    }

    .list-news__content-small{
        display: block;
        margin-top: 1.5rem;

        @include media-breakpoint-down(xl){
            position: relative;
            top: -5px;
            margin-top: 0;
        }
    }

    .list-news__name-small{
        @include font-bold;
        display: block;
        font-size: 1.6rem;
        color: #353534;
    }


}

@include list_news;
