//стандартные значения для ширины
$container-max-widths: (
    sm: 600px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

//дополнительный контейнер для не стандартной ширины
$container-max-widths-additional: (
    xxl: 1400px
);

//объдиняем, чтобы можно было использовать не стандартные значения ширины
//в миксинах для генерации контейнеров
$container-max-widths-wide: map-merge($container-max-widths, $container-max-widths-additional);


$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 860px,
    lg: 915px,
    xl: 1215px,
    xxl: 1515px
);


$font-family-base: "Open Sans", sans-serif;
$font-family-bold: "Open Sans", sans-serif;
$line-height-base: 1.5;
$line-height-custom: $line-height-base;
