.link-button {
    @include font-regular;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: 8px 24px;
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #71941c;
    border: 1px solid transparent;
    border-radius: 20px;
    border-radius: 0;
    transition: none;

    &:hover {
        text-decoration: none;
        background-color: $color-hover;
        box-shadow: 0 0 6px 2px rgba(#000, .4);
        box-shadow: none;
    }

    @include media-breakpoint-down(md){
        padding: 15px;
    }


}
