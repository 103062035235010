// background
$hc-offcanvas-nav-background-color:  #597c2a;

// color text
$hc-offcanvas-nav-text-color: #fff;

$hc-offcanvas-nav-text-size:          14px;

//border color
$hc-offcanvas-nav-border-color: #597c2a;

//color arrow
$hc-offcanvas-nav-arrow-color: #fff;

//color arrow close
$hc-offcanvas-nav-arrow-color-close: #fff;

//color title
$hc-offcanvas-nav-title-color: #fff;


.hc-offcanvas-nav {
    font-family: sans-serif;

    &,
    .nav-wrapper {
        &::after {
            background: rgba(0, 0, 0, .3);
        }
    }

    .nav-container,
    .nav-wrapper,
    ul {
        background: $hc-offcanvas-nav-background-color;
    }

    .nav-content > {
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding: 20px 17px;
            margin: 0;
            font-size: round($hc-offcanvas-nav-text-size * 1.35);
            font-weight: normal;
            color: $hc-offcanvas-nav-title-color;

            &:not(.nav-title):not(.level-title) {
                padding: 15px 17px;
                font-size: round($hc-offcanvas-nav-text-size * 1.14);
                background: $hc-offcanvas-nav-background-color;
            }
        }
    }

    .nav-item-link,
    li.nav-close a,
    .nav-back a {
        z-index: 1;
        padding: 10px 17px;
        font-size: $hc-offcanvas-nav-text-size;
        color: $hc-offcanvas-nav-text-color;
        background: rgba(0, 0, 0, 0);
        border-bottom: 1px solid $hc-offcanvas-nav-border-color;
        transition: background .1s ease;

        &:focus,
        &:focus-within {
            z-index: 10;
        }

        &[disabled] {
            color: rgba($hc-offcanvas-nav-text-color, .5);
        }
    }

    div.nav-back {
        & + ul > li:first-child > .nav-item-wrapper > .nav-item-link {
            border-top: none !important;
        }
    }

    &:not(.touch-device) {
        li:not(.nav-item-custom) {
            a:not([disabled]) {
                &:hover {
                    background: darken($hc-offcanvas-nav-background-color, 2%);
                }
            }
        }
    }

    .nav-custom-content {
        padding: 14px 17px;
        font-size: $hc-offcanvas-nav-text-size;
        border-bottom: 1px solid $hc-offcanvas-nav-border-color;
    }

    .nav-highlight {
        background: darken($hc-offcanvas-nav-background-color, 4%);
    }

    .nav-wrapper {
        & > .nav-content > {
            ul {
                &:first-of-type {
                    & > li {
                        &:first-child:not(.nav-back):not(.nav-close) {
                            & > .nav-item-wrapper {
                                & > .nav-item-link {
                                    border-top: 1px solid $hc-offcanvas-nav-border-color;

                                    & + a {
                                        border-top: 1px solid $hc-offcanvas-nav-border-color;
                                    }
                                }
                            }
                        }
                    }
                }

                &:not(:last-child) {
                    border-bottom: 2px solid $hc-offcanvas-nav-border-color;
                }

                & + {
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        margin-top: -2px;
                    }
                }
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                & + ul {
                    & > li {
                        &:first-child:not(.nav-back):not(.nav-close) {
                            & > .nav-item-wrapper {
                                & > .nav-item-link {
                                    border-top: 1px solid $hc-offcanvas-nav-border-color;
                                }
                            }
                        }
                    }
                }
            }

            .nav-title,
            .level-title,
            .nav-close {
                & + {
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        border-top: 1px solid $hc-offcanvas-nav-border-color;
                    }
                }
            }
        }
    }

    li {
        color: $hc-offcanvas-nav-text-color;

        &.nav-parent {
            .nav-item-link {
                &:last-child {
                    padding-right: 58px;
                }

                &:not(:last-child) {
                    margin-right: 45px;
                }
            }
        }
    }

    .nav-close-button span,
    .nav-parent .nav-next,
    .nav-back span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 45px;
        text-align: center;
        cursor: pointer;
        transition: background .1s ease;
    }

    .nav-close-button {
        position: relative;
        display: block;

        span {
            &::before,
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 18px;
                height: 18px;
                margin-top: -9px;
                content: '';
                border-top: 2px solid $hc-offcanvas-nav-arrow-color;
                border-left: 2px solid $hc-offcanvas-nav-arrow-color;
            }

            &::before {
                margin-left: -23px;
                transform: rotate(135deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    .nav-content > .nav-close {
        position: relative;
        z-index: 2;

        a {
            z-index: 1;
            box-sizing: border-box;
            font-size: $hc-offcanvas-nav-text-size;
            color: $hc-offcanvas-nav-text-color;
            text-decoration: none;
            background: rgba(0, 0, 0, 0);

            &:not(.has-label) {
                height: 50px;
            }

            &.has-label {
                padding: 14px 17px;
                border-top: 1px solid $hc-offcanvas-nav-border-color;
            }

            &:hover {
                background:
                    radial-gradient(
                        farthest-corner at top right,
                        rgba(0, 0, 0, .1),
                        rgba(0, 0, 0, 0)
                    );
                border: none;
            }
        }

        &.has-label + ul {
            margin-top: -1px;
        }
    }

    &:not(.nav-close-button-empty) {
        .nav-content > .nav-close {
            margin-bottom: -1px;
        }
    }

    .nav-title {
        & + .nav-close {
            a:not(.has-label) {
                position: absolute;
                top: -66px;
                right: 0;
                width: 45px;
                height: 66px;
                line-height: 66px;
            }
        }
    }

    &.nav-close-button-empty {
        .nav-title {
            padding-right: 55px;
            padding-left: 24px;
        }
    }

    li.nav-close {
        a:not(.has-label) {
            height: 49px;
        }
    }

    .nav-content > .nav-close:first-child a,
    .nav-title + .nav-close a.has-label,
    li.nav-close a,
    .nav-back a {
        background: darken($hc-offcanvas-nav-background-color, 3%);
        border-top: 1px solid $hc-offcanvas-nav-border-color;
        border-bottom: 1px solid $hc-offcanvas-nav-border-color;

        &:hover {
            background: darken($hc-offcanvas-nav-background-color, 4.3%);
        }
    }

    li.nav-close,
    li.nav-back {
        &:not(:first-child) {
            a {
                margin-top: -1px;
            }
        }
    }

    a.nav-next {
        border-bottom: 1px solid $hc-offcanvas-nav-border-color;
        border-left: 1px solid transparent;
    }

    .nav-next,
    .nav-back {
        span {
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                box-sizing: border-box;
                width: 8px;
                height: 8px;
                margin-left: -2px;
                content: '';
                border-top: 2px solid $hc-offcanvas-nav-arrow-color;
                border-left: 2px solid $hc-offcanvas-nav-arrow-color;
                transform-origin: center;
            }
        }
    }

    .nav-next {
        span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &::before {
                transform: translate(-50%, -50%) rotate(135deg);
            }
        }
    }

    .nav-back {
        span {
            &::before {
                margin-left: 2px;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    /* Left */

    &.nav-position-left {
        &.nav-open.nav-levels-overlap {
            .nav-wrapper {
                box-shadow: 1px 0 2px rgba(0, 0, 0, .2);
            }
        }
    }

    /* Right */

    &.nav-position-right {
        &.nav-open.nav-levels-overlap {
            .nav-wrapper {
                box-shadow: -1px 0 2px rgba(0, 0, 0, .2);
            }
        }

        .nav-next {
            span {
                &::before {
                    margin-left: 2px;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        .nav-back {
            span {
                &::before {
                    margin-left: -2px;
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }
        }
    }

    /* Top */

    &.nav-position-top {
        &.nav-open {
            .nav-wrapper {
                box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            }
        }

        .nav-next {
            span {
                &::before {
                    margin-right: -2px;
                    margin-left: 0;
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }
        }

        .nav-back {
            span {
                &::before {
                    margin-right: -2px;
                    margin-left: 0;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }

    /* Bottom */

    &.nav-position-bottom {
        &.nav-open {
            .nav-wrapper {
                box-shadow: 0 -1px 2px rgba(0, 0, 0, .2);
            }
        }

        .nav-next {
            span {
                &::before {
                    margin-right: -2px;
                    margin-left: 0;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }

        .nav-back {
            span {
                &::before {
                    margin-right: -2px;
                    margin-left: 0;
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }
        }
    }

    /* Expand & none */

    &.nav-levels-expand,
    &.nav-levels-none {
        ul {
            .nav-wrapper {
                background: transparent;
                box-shadow: none;
            }
        }

        li {
            &.level-open {
                background: darken($hc-offcanvas-nav-background-color, 4%);

                & > .nav-item-wrapper > {
                    a {
                        border-bottom: 1px solid $hc-offcanvas-nav-border-color;

                        &:hover {
                            background: darken($hc-offcanvas-nav-background-color, 3%);
                        }
                    }

                    .nav-next,
                    a > .nav-next {
                        span {
                            &::before {
                                margin-top: -2px;
                                transform: translate(-50%, -50%) rotate(-135deg);
                            }
                        }
                    }
                }
            }
        }

        @for $i from 1 through 5 {
            .nav-wrapper-#{$i} .nav-item-link {
                padding-left: calc(17px + 20px * #{$i});
            }
        }
    }

    /* RTL */

    &.rtl {
        a.nav-next {
            border-right: 1px solid darken($hc-offcanvas-nav-background-color, 6%);
            border-left: none;
        }

        .nav-title + .nav-close a:not(.has-label),
        .nav-close-button span,
        .nav-next,
        .nav-back span {
            right: auto;
            left: 0;
        }

        li {
            &.nav-parent {
                .nav-item-link {
                    &:last-child {
                        padding-right: 17px;
                        padding-left: 58px;
                    }

                    &:not(:last-child) {
                        margin-right: 0;
                        margin-left: 45px;
                    }
                }
            }
        }

        @for $i from 1 through 5 {
            .nav-wrapper-#{$i} li.nav-item .nav-item-link {
                padding-right: calc(17px + 20px * #{$i});
            }
        }
    }
}


.hc-offcanvas-nav .nav-close-button span::before,
.hc-offcanvas-nav .nav-close-button span::after{

    border-color: $hc-offcanvas-nav-arrow-color-close;
}


//project
.mobile-from-horizont,
.mobile-from-left{
    @include reset-ul;

    border-top: 1px solid #71941c;
    border-bottom: none !important;

    a{
        border-top: none !important;
        border-bottom: none !important;
    }
}

.mobile-from-horizont{
    padding-bottom: 20px !important;
    border-top: none !important;
}

.mobile-from-left{
    padding-top: 20px !important;
}


.nav-item-wrapper::before{
    @include pseudo;
    @include centerer();
    z-index: 100;
    width: 22px;
    height: 1px;
    background-color: #fb9b1f;
}


.hc-offcanvas-nav .nav-item-link,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a{
    padding-left: 30px;
}

.nav-title{
    font-weight: bold !important;
}

.menu-item-search{

    padding-bottom: 20px !important;
    border-bottom: 1px solid #71941c;

    .nav-item-wrapper::before{
        display: none;
    }

    .nav-item-link{
        padding: 0;
    }

    .block-search{
        padding: 0;
        padding: 20px 10px;
        background-color: #4f6614;
    }
}

.mobile-menu-social .nav-item-wrapper::before{
    display: none;
}

.mobile-menu-social{
    display: flex;
    align-items: center;
    background-color: #4f6614 !important;

}

.mobile-menu-social a{
    min-height: auto;
    padding: 5px !important;
    border-bottom: none !important;
}

.vsv-mobile{
    padding: 10px !important;
    margin-left: auto !important;
    background-color: #243200;


    img{
        min-width: 64px;
    }
}
