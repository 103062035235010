.block-4__row{
    @include reset-ul;
    @include make-row(60px);
}

.block-4__col{
    @include make-col-ready(60px);
    @include make-col(3);
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-bottom: 8.3rem !important;

    @include media-breakpoint-down(lg){
        @include make-col(6);
    }

    @include media-breakpoint-down(xs){
        @include make-col(12);
    }
}

.block-4__link{
    display: block;
    line-height: 1.3;

    &:hover{
        color: $color-hover;
    }

    &:hover .block-4__title{
        color: $color-hover;
    }
}

.block-4__img{
    display: block;
    margin-bottom: 1.2rem;
}

.block-4__title{
    display: block;
    font-size: 1.6rem;
    color: $color-black;
    @include font-bold;
}


.list-video-col,
.list-photo-col{
    position: relative;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    @include trans;

    &:hover{
        background-color: #faf1e5;
    }
}

.list-video-col .block-4__img {
    position: relative;

    &::before{
        @include pseudo();
        @include centerer(true, true);
        width: 110px;
        height: 110px;
        background-image: url("/desimages/icons_play.svg");
        opacity: .5;
        @include trans;
    }
}

.list-video-col .block-4__link:hover .block-4__img {
    &::before{
        opacity: 1;
    }
}
