$color-black: #353534;
$color-white: #fff;
$color-hover: #fb431f;

$site-width:1900;

// mobile menu
$color-primary-mob: #f3faff;


//используются в миксинах для адаптива
$desktop-width:1200px;
$tablet-width: 768px;
$mobile-small-width: 576px;

//********* general_styles *************
//базовый размер шрифта in general styles
$custom-base-font-size: 1.6rem;
//********* general_styles end *************

$c1: #fb9b1f;
$c2: #687c29;
$c3: #81a52a;
$c4: #71941c;
$c5: #d0c0a8;
$c6: #353534;
$c7: #020101;
$c8: #faf1e5;
$c9: #c0f0c8;
