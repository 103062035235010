.border-accent{
    border-top: 6px solid #fb9b1f;
}

.g-container{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1910px;
    margin: 0 auto;


    .col-left{
        flex-shrink: 0;
        width: 330px;
        background-color: #587c29;

        @include media-breakpoint-down(xl){
            width: 290px;
        }
        @include media-breakpoint-down(lg){
            display: none;
        }

    }

    .col.col-rigth{
    }

    .footer{
        width: 100%;
    }
}

.container{
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
}

.block-social{
    display: flex;
    justify-content: space-between;
}


//вторичка
.inner__row{
    @include make-row(0);
    flex-wrap: nowrap;

    @include media-breakpoint-down(md){
        flex-direction: column;
    }
}

.inner__col-1{
    @include make-col-ready(0);
    @include column-grow();
    max-width: 1000px;
    margin-right: 20px;

    @include media-breakpoint-down(md){
        margin-right: 0;
    }
}

.inner__col-2{
    @include make-col-ready(0);
    @include column-fix(360px);
    margin-left: auto;

    @include media-breakpoint-down(md){
        margin-top: 3rem;
    }
}
