@mixin top_menu {

    $color-link:#fff;

    .top-menu{
        @include reset-ul;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        height: 100%;


    }

    .top-menu > li{

    }

    .top-menu a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 2.8rem;
        font-size: 1.4rem;
        color: #fff;
        text-transform: uppercase;
        @include font-bold;

        &:hover{
            text-decoration: none;
            background-color: #fb9b1f;
        }
    }

    .top-menu__active{
        background-color: #fb9b1f;
    }


}

@include top_menu;
