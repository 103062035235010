.footer {
    padding: 33px 73px;
    background-color: #81a52a;

    @include media-breakpoint-down(md){
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (max-width: 440px) {
        padding-right: 15px;
        padding-left: 15px;
    }

    a{
        color: #c0f0c8;
        text-decoration: none;

        &:hover{
            color: $color-white;
            text-decoration: underline;
        }
    }

    span{
        color: #c0f0c8;
    }
}

.footer__row{
    @include make-row(0);
    flex-wrap: nowrap;

    @include media-breakpoint-down(xl){
        flex-wrap: wrap;

        & > div {
            margin-bottom: 25px;
        }
    }
}

.footer-col-1,
.footer-col-2,
.footer-col-3,
.footer-col-4{
    @include media-breakpoint-down(xs){
        margin-bottom: 30px;
    }
}

.footer-col-1{
    width: 100%;
    max-width: 307px;
    padding-right: 73px;

    a,
    span{
        display: block;
        font-size: 1.4rem;
    }

}

.footer-col-1 div{
    color: #c0f0c8;

    &:first-child{
        @include font-bold;
        margin-bottom: 4px;
        font-size: 1.6rem;
        text-transform: uppercase;
    }

    &:last-child{
        margin-top: 12px;
        font-size: 1.4rem;

    }
}

.footer-col-1__title{
    @include font-bold();
}

.footer-col-2{
    width: 100%;
    max-width: 400px;

    & .gl-pad{
        padding: 0;
    }

    @include media-breakpoint-down(xl){
        max-width: 280px;
    }

    ul{
        @include reset-ul;

        li{
            padding-bottom: 14px;
            padding-left: 33px;
            font-size: 1.4rem;
            color: #c0f0c8;
        }
    }
}

.footer-col-3{
    width: 100%;
    max-width: 480px;

    @include media-breakpoint-down(xl){
        max-width: 430px;
    }
}

.footer-col-4{
    width: 100%;
    max-width: 540px;

    a{
        display: inline-block;
        margin-right: 10px;
    }
}

.footer-col-4__title{
    display: block;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    line-height: 1.3;
    color: #c0f0c8;
    @include font-regular;
}


.footer__title{
    font-size: 1.6rem;
    text-transform: uppercase;
    @include font-bold;

}

.footer__year{
    @include font-bold;
    font-size: 1.4rem;
}

//меню в футере
.footer-col-3 .sm1{
    margin: 0;
    margin-bottom: 2px;
}

.footer-col-3 .sm1 li{
    padding-left: 4rem;

    &::before{
        top: 1.4rem;
        width: 30px;
        height: 2px;
        background-color: #687c29;
    }
}

.cicon1,
.cicon2,
.cicon3,
.cicon4{
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 20px;
}

.cicon1{
    background-image: url('/desimages/icons_adress.svg');
}

.cicon2{
    background-image: url('/desimages/icons_phone.svg');
}

.cicon3{
    background-image: url('/desimages/icons_e-mail.svg');
}

.cicon4{
    background-image: url('/desimages/icons_contact.svg');
}
