//поделяшки (вертикальные)

/* stylelint-disable */
.ya-share2__list{
  @include reset-ul;
    display: flex !important;
    flex-direction: column !important;

    @include media-breakpoint-down(xs) {
        flex-direction: row !important;
    }
}

.ya-share2__item {
  margin-bottom: 1rem!important;
}

.ya-share2__icon{
    width: 45px !important;
    height: 45px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 34px 34px !important;
}

.ya-share2__badge{
  border-radius: 50%!important;
}

.counters_share{
  margin-bottom: 3px;
}