.title-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 36px;

    @include media-breakpoint-down(sm){
        flex-wrap: wrap;
    }
}

.title-section__title{
    @include font-bold();
    margin-right: 3.3rem;
    font-size: 3.6rem;
    color: #687c29;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:hover{
        color: #fb9b1f;
        text-decoration: none;
    }

    @media (max-width: 440px) {
        font-size: 2rem;
    }
}


.title-section__all{
    height: 100%;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    font-size: 1rem;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #fb9b1f;

    a,
    span{
        display: flex;
        align-items: center;
        min-height: 2.2rem;
        font-size: 1rem;
        color: $color-white;

        &:hover{
            text-decoration: none;
            background-color: $color-hover;
        }

    }

    &:hover{
        text-decoration: none;
        background-color: $color-hover;
    }
}


// блоки

.list-resource{
    .title-section__title{
        @include font-bold;
        font-size: 2.4rem;
    }

    .title-section{
        margin-bottom: 1.6rem;
    }

}


.media-tab{
    .title-section{
        margin-bottom: 10px;
    }
}
