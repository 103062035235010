@mixin search {
    .search{
        background-color: #81a52a;

        &:hover{
            background-color: #fff;

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #71941c;
                opacity: 1; /* Firefox */
            }
        }

        &:hover button{
            background-image: url('/desimages/icons_search-hover.svg');
        }
    }

    .search__input{
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 3.8rem;

    }

    .search__input input{
        flex-grow: 1;
        padding-left: 20px;
        font-size: 1.1rem;
        color: #d0c0a8;
        text-transform: uppercase;
        background-color: transparent;
        border: none;

        &:hover{
            color: #71941c;
        }


    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #d0c0a8;
        opacity: 1; /* Firefox */
    }

    .search__input button{
        flex-shrink: 0;
        width: 34px;
        background-color: transparent;
        background-image: url('/desimages/icons_search.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border: none;
    }


}

@include search;


.inner-search{
    .search__input{
        margin-bottom: 2rem;

    }

    .search__input input{
        border: 1px solid #000;
    }

    .search__input input[type=submit]{
        max-width: 126px;
        margin-left: 15px;
        color: #fff;
        background-color: #71941c;
    }


    .search_info{
        margin-bottom: 2rem;
    }

    .search_item{
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #71941c;

        &:last-child{
            border-bottom: none;
        }
    }

    .d-number{
        min-width: 50px;

        @include media-breakpoint-down(sm){
            min-width: 20px;
        }
    }

    .d-result .search_title{
        font-weight: bold;
        color: #000;
    }

    .news_name.news_date{
        display: flex;

        a{
            color: grey;
        }

        .date-after-title .gl-date{
            display: inline-block;
            font-size: 1.2rem;
        }
    }

    .search_image{
        margin-right: 20px;
    }


    .search_descript{
        margin-top: 15px;

        span{
            font-weight: bold;
            color: #000;
        }
    }
}
