//разное


//поиск
.res-news{
    display: block;
    margin-top: 10px;
    color: red;
}

.search-results-out + .pages__wrapper .pages{
    margin-top: 0;
    border-top: none;
}

.res_number{
    margin-bottom: 20px;

    span{
        font-weight: bold;
    }
}

.minp_news_item_data{
    color: #737373;
}


//календарь список событий \ вторичка

.event_top_date {
    margin-bottom: 0;
    line-height: 1;
    color: #424242;
    text-transform: uppercase;
}

.event__item {
    padding-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #d7d6d4;
}

.event__title{
    margin-bottom: 20px;

    /*    font-size: 3rem; */
    font-size: 2.4rem;
    font-weight: bold;
    color: #424242;
}

.event__text{
    margin-bottom: 20px;
    margin-bottom: 30px;
    font-size: 1.8rem;
    color: #424242;
}

.event__desc {
    font-size: 1.8rem;
    color: #424242;
}

.event__info{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 35px;
}

.event__img{
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 190px;
    margin-right: 20px;
}

.event__content {
    .custom-date {
        margin-top: 0 !important;
    }
}

.event__link {
    font-size: 1.8rem;
    font-weight: bold;
    color: #424242;

    .custom-date {
        margin-top: 0 !important;
    }
}


//************************тарифные квоты

.tarif{
    @include reset-ul;
    display: flex;
    flex-wrap: wrap;

    .active{
        color: $color-white;
        background-color: #fb9b1f;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 184px;
        min-height: 42px;
        color: $color-black;
        text-align: center;
        background-color: #faf1e5;
        @include font-bold;

        &:hover{
            color: $color-white;
            text-decoration: none;
            background-color: $color-hover;
        }
    }
}

ul.tarif li{
    margin-right: 1rem;
    margin-bottom: 1rem;

    &::before {
        display: none;
    }
}

//по умолчанию контект скрыт
.tarif__content{
    display: none;

    a[href$="html"]{
        display: inline-block;
        height: 26px;
        padding-left: 4rem;
        background: url("/desimages/files/icons_text.svg") no-repeat left 3px;
        background-repeat: no-repeat !important;

        &:hover{
            background: url("/desimages/files/icons_text1.svg");
        }
    }
}

.js-tarif-insert-block{
    margin-top: 4rem;
}

//внутри блока контент показываем
.js-tarif-insert-block .tarif__content{
    display: block;
}


//*****************страница контакты, таблица с контактами
table#table2 p,
table#table3 p{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: $color-black;
}

table#table2 tr,
table#table3 tr{
    &:hover{
        background-color: #faf1e5;
    }
}

table#table2 a,
table#table3 a{
    color: $color-black;

    &:hover{
        text-decoration: none;
    }
}

table#table2 .fake-link,
table#table3 .fake-link{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 4px 16px;
    margin-left: auto;
    font-size: .9rem;
    color: #81a52a;
    text-transform: uppercase;
    background-color: #faf1e5;

    &:hover{
        color: $color-white;
        background-color: $color-hover;
    }

}

table#table3{
    width: 100%;
}

#workerer{
    display: block;
    padding-bottom: 3.5rem;
    margin-bottom: 5.5rem;
    border-top: 1px solid #81a52a;
    border-bottom: 1px solid #81a52a;

    h2{
        font-size: 24px;
    }

    h3{
        font-size: 16px;
    }

    ul{
        margin-top: 0;
    }

    p{
        padding-left: 27px;
    }
}


#info-min{
    display: flex;

    @include media-breakpoint-down(md){
        flex-direction: column;
    }
}

#info-left{
    flex-shrink: 0;
    width: 454px;
    padding-left: 51px;
}

#adr,
#tel,
#eml{
    position: relative;

    &::before{
        left: -35px;
        width: 24px;
        height: 24px;
        background-image: url("/desimages/icons_adress.svg");
        background-repeat: no-repeat;
        @include pseudo();
    }
}

#tel{
    &::before{
        background-image: url("/desimages/icons_phone.svg");
    }
}

#eml{
    &::before{
        background-image: url("/desimages/icons_e-mail.svg");
    }
}


//**********************вопросы и ответы question-ru
.quest_ans__init{
    @include reset-ul;
}

.quest_ans__quest{
    display: block;
    @include font-bold;
    margin-bottom: 2rem;
}

.quest_ans__otvet{
    display: block;
}

.quest_ans__ans{
    display: block;
    margin-top: 8px;
    margin-bottom: 0;
    font-style: italic;
    @include font-regular;
}

.quest_ans__block{
    padding-bottom: 5rem !important;
    margin-bottom: 4.5rem !important;
    border-bottom: 1px solid #81a52a;

    &:last-child{
        border-bottom: none;
    }
}


//руководство
.managers{
    @include reset-ul;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img{
        display: block;
        float: left;
        max-width: initial;
        margin-right: 30px;
    }
}

.managers li{
    position: relative;
    flex-basis: 670px;
    width: 100px;
    max-width: 670px;
    padding-right: 10px;
    margin-bottom: 75px;

    &:nth-child(1),
    &:nth-child(2){
        background-color: #faf1e5;
    }

    @include media-breakpoint-down(sm){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    a{
        position: absolute;
        bottom: 10px;
        left: 232px;
        display: flex;
        align-items: center;
        height: 100%;
        height: 2.2rem;
        padding-right: 1.4rem;
        padding-left: 1.4rem;
        font-size: 1rem;
        color: #fff;

        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        background-color: #fb9b1f;
        -webkit-box-align: center;

        @include media-breakpoint-down(sm){
            position: relative;
            bottom: 0;
            left: 0;
        }

        &:hover{
            text-decoration: none;
            background-color: #fb431f;
        }

    }

    & > p:first-of-type{
        padding-top: 20px;
        font-weight: bold;
    }

    & > p:nth-child(3){
        font-size: 2.2rem;
        font-weight: bold;
    }
}


.video-block-width{
    max-width: 1215px;
}


//отраслевые ресурсы
.news_list_one{
    margin-bottom: 40px;

    // .gl-date{
    //     font-size: 1.6rem;
    //     color: $color-black;
    // }

    .news_list_title{
        margin-top: 15px;
    }
}


.title-img img{
    @include img-object-fit();
}
