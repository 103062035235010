@mixin columns($width) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        flex-basis: $width;
    }
}

@mixin media-laptop {
    @media (max-width: $desktop-width) {
        @content;
    }
}

@mixin media-tablet {
    @media (max-width: $tablet-width) {
        @content;
    }
}

@mixin media-mobile {
    @media (max-width: $mobile-small-width) {
        @content;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    position: $pos;
    display: $display;
    content: $content;
}

//выравнивание элеметна по  вертикали и/или горизонтали
@mixin centerer($horizontal: false, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin column-fix($width) {
    flex-basis: $width;
    flex-shrink: 0;
    //2300 - просто число больше 1920 (full hd)
    // @media screen and (min-width: 2300px) {
    //     $width-percent: 100%*strip-unit($width)/strip-unit($site-width);
    //     flex-basis: $width-percent;
    //     // flex-grow: 1;
    // }
}

@mixin column-grow() {
    // flex-basis: 0;
    // flex-grow: 999;
    width: 100%;
}

//проверить
@mixin col-auto() {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

//добавляет 2 линии справа и слево от текста
//<div style=" overflow: hidden; text-align: center;">
//   <h3 class="address__city">city</h3>
//</div>

/*
.address__city{
  position: relative;
  display: inline-block!!!;

  &::before,
  &::after{
      @include pseudo();
      @include centerer();
  }

  @include line-middle-text(12px);
*/
@mixin line-middle-text($margin:15px) {
    &::before{
        right: 100%;
        width: 1000px;
        margin-right: $margin;
    }

    &::after{
        left: 100%;
        width: 1000px;
        margin-left: $margin;
    }
}


// наложение полупрозрачного затемнения для картинки в бг
@mixin box-shadow{
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .2);
}


//$fit : cover or contain
@mixin img-object-fit($fit:cover) {
    width: 100%;
    height: 100%;
    object-fit: $fit;
}

//сбросить мардины и паддинги у списков наследуемые от general_styles
@mixin reset-ul {
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin: 0;
    }

    li::before{
        display: none;
    }

}

//использовать совместно с @include make-col-ready();
@mixin flex-column-perсent($width) {
    flex: 0 0 $width#{'%'};
    max-width: $width#{'%'};
}


@mixin font-regular{
    // font-family: "raleway regular", sans-serif;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

@mixin font-bold{
    // font-family: "raleway bold", sans-serif;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

@mixin font-medium{
    font-family: "raleway medium", sans-serif;
}


@mixin trans {
    transition: all .2s;
}
