@mixin header {
    .header__row-1,
    .header__row-11{
        position: absolute;
        top: 60px;
        left: 0;
        display: flex;
        width: 100%;

        .mobile-header &{
            @include media-breakpoint-down(lg){
                top: 6px;
            }
        }
    }

    .header__lang{
        flex-shrink: 0;
        width: 72px;
        padding: 14px 0;
        padding-left: 6px;
        background-color: #71941c;
    }

    .header__gerb{
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: 136px;
        background-color: #71941c;

        & a{
            flex-shrink: 0;
            max-width: 54px;

            & + a{
                margin-left: 10px;
            }
        }
    }

    .header__name{
        flex-shrink: 0;
        width: 365px;
        padding-right: 60px;

        background: rgb(113, 148, 28);
        background: linear-gradient(90deg, rgba(113, 148, 28, 1) 80%, rgba(129, 165, 42, 1) 100%);

        .mobile-header &{
            @include media-breakpoint-down(lg){
                flex-shrink: 1;
                flex-grow: 1;
                width: 100%;
            }
        }

        a{
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 1.5rem;
            line-height: 1.3;
            color: #fff;
            text-transform: uppercase;
            @include font-bold;

            @include media-breakpoint-down(lg){
                max-width: 320px;
            }

            &:hover{
                text-decoration: none;
            }
        }
    }

    .header__menu{
        @extend .col;
        background-color: #81a52a;
    }


    .header__row-2{
        margin-top: 100px;
    }

    .block-search{
        padding: 0 12px;
        padding-bottom: 1.2rem;
    }

    .block-left-menu{
        padding-bottom: 1.2rem;
    }

    .mobile-header{
        display: none;
        @include media-breakpoint-down(lg){
            display: block;
        }

        @media (max-width: 444px) {
            display: none !important;
        }


    }

    .title-img{
        @include media-breakpoint-down(lg){
            display: none;
        }
    }
}

@include header;


#main-nav{
    display: none;
}

#mobile_menu{
    display: none;
}


.line-blue{
    height: 5px;
    background-color: #1a6b9f;
}


.mobile-button-menu{
    right: 16px;
    width: 50px !important;
    padding-right: 20px;
    font-size: 0;
    // background-image: url('/desimages/menu.png');
    background-repeat: no-repeat;
    background-position: right center;

    // @media (max-width: 444px) {
    //     display: none;
    // }
}

.header__row-2 .mobile-button-menu{
    @media (max-width: 330px) {
        display: none !important;
    }
}

.mobile-header__low{
    display: none;

    @media (max-width: 444px) {
        display: block !important;
    }

}

.header__row-11__low{
    top: 0 !important;
    flex-wrap: wrap;
    background-color: #fb9b1f;

    .header__lang{
        width: 100%;
        padding: 0;

    }

    .header__name{
        padding-top: 10px;
        padding-bottom: 10px;

        a{
            line-height: 1.2;
        }
    }

    .header__gerb{
        flex-direction: column;
        width: 54px;
        padding-top: 5px;

        img{
            max-width: 30px;
        }

        a{
            margin: 0;
        }
    }

    .mobile-header__low__row{
        display: flex;
    }

    .mobile-button-menu{
        right: 7px;
    }

    .header-line-1{
        position: relative;
        flex-grow: 1;
        padding-left: 7px;

    }

    .lang{
        flex-direction: row;
        align-items: center;
        background-color: #fb9b1f;
    }

    .lang li{
        width: 50px;
    }

    .lang a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        height: 40px;

        &.active{
            background-color: #71941c;
        }

        &:hover{
            color: #fb9b1f;
            background-color: #71941c;
        }
    }
}

