@mixin taxonomy {

    $l-color-text: #6d6c6c;

    .taxonomy{
        @include reset-ul;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 3.2rem;
    }

    .taxonomy a,
    .taxonomy span{
        font-size: 1.1rem;
        color: $l-color-text;
        text-transform: uppercase;

    }

    .taxonomy a{
        &:hover{
            color: $l-color-text;
            text-decoration: underline;
        }
    }

}

@include taxonomy;
