$hc-offcanvas-nav-trigger-width:              30px !default;
$hc-offcanvas-nav-trigger-height:             24px !default;
$hc-offcanvas-nav-trigger-line-width:         4px !default;
$hc-offcanvas-nav-trigger-color:              #34495e !default;
$hc-offcanvas-nav-trigger-transform-speed:    .2s !default;

@mixin hc-hamburger($type: 'default', $width: $hc-offcanvas-nav-trigger-width, $height: $hc-offcanvas-nav-trigger-height, $line-width: $hc-offcanvas-nav-trigger-line-width, $color: $hc-offcanvas-nav-trigger-color, $transform-speed: $hc-offcanvas-nav-trigger-transform-speed) {
    position: absolute;
    top: 0;
    @include centerer();
    z-index: 9980;
    display: none;
    width: $width;
    min-height: $height;
    cursor: pointer;
    user-select: none;

    $line-offset: ($height - $line-width) / 2;

    span {
        top: 50%;
        width: $width;
        transform: translateY(-50%);
        transform-origin: 50% 50%;

        &,
        &::before,
        &::after {
            position: absolute;
            left: 0;
            display: block;
            height: $line-width;
            background: $color;
            transition: all $transform-speed ease;
        }

        &::before,
        &::after {
            width: 100%;
            content: '';
        }

        &::before {
            top: -$line-offset;
        }

        &::after {
            bottom: -$line-offset;
        }
    }

    &.toggle-open {
        span {
            background: rgba(0, 0, 0, 0);
            transform: rotate(45deg);

            &::before {
                transform: translate3d(0, $line-offset, 0);
            }

            &::after {
                transform: rotate(-90deg) translate3d($line-offset, 0, 0);
            }
        }
    }
}
