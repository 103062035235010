html {
    min-width: 320px;
    height: 100%;
}

//прижать футер к низу
body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 320px;
    height: 100%;
    min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
a{
    overflow-wrap: break-word;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.stretched-link {
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        // Just in case `pointer-events: none` is set on a parent
        pointer-events: auto;
        content: "";
        // IE10 bugfix, see https://stackoverflow.com/questions/16947967/ie10-hover-pseudo-class-doesnt-work-without-background-color
        background-color: rgba(0, 0, 0, 0);
    }
}

.fixed-top{
    position: fixed;
    top: -1px;
    right: 0;
    left: 0;
    z-index: 1030;
}

//колонка занимает все доступное пространство
.col {
    flex: 1 1 auto;
    min-width: 0;

}


.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.overflow_block {
    overflow-x: auto;
}

.main{
    flex-grow: 1;
}

.inner{
    flex: 1 1 auto;
}

//картинки на внутренних адаптивны
.inner img{
    @include img-fluid;
}

/* для картинок в таблицах, чтобы не сжимались */
table img{
    max-width: initial;
}


//project
//для библиотеки lazysizes заглушка пока загружаются изображение
.lazyload {
    opacity: 0;
}

.lazyloading {
    background: #f7f7f7 url('/desimages/loader.webp') no-repeat center;
    opacity: 1;
    transition: opacity 300ms;
}


//паддинги для блока
.gl-pad{
    padding: 56px 50px 100px 70px;

    &.list-anonce {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    &.list-anonce--smi{
        padding-top: 65px;
        padding-bottom: 68px;
    }

    @include media-breakpoint-down(xl){
        padding-top: 30px;
        padding-right: 20px;
        padding-left: 20px;
    }

    @media (max-width: 440px) {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.gl-date{
    @include font-regular();
    display: block;
    margin-top: 6px;
    font-size: 1.1rem;
    color: #a3a3a3;

    .list-news__item-big &{
        color: $color-white;
    }
}


//для проекта
.list-anonce .js-flex-start{
    justify-content: flex-start;

    & > li{
        margin-right: 40px;
    }
}

.inner-wrapper{
    padding: 57px 70px 130px 70px;

    @include media-breakpoint-down(sm){
        padding: 30px 15px;
    }
}

.inner-title-section{
    margin-top: 0;
    margin-bottom: 3.6rem;
    font-size: 3.6rem;
    color: $color-black;
    @include font-bold;
}


.to-all-item{
    display: inline-block;
    margin-top: 4rem;
    color: #fb9b1f;

    &:hover{
        color: $color-hover;
    }
}
