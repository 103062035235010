$width_arrow: 106px;

.big-image-block{
    position: relative;
    display: flex;
    align-items: center;
    padding-right: $width_arrow;
    padding-left: $width_arrow;
    margin-bottom: 50px;
    background-color: #dbdbdb;

    @media (max-width: 800px) {
        padding-right: 30px;
        padding-left: 30px;
    }
}


.big-image-block__title{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 20px;
    color: $color-white;
    text-align: center;
    background-color: rgba($color: #000, $alpha: .5);

    @include media-breakpoint-down(sm){
        position: relative;
        padding: 10px;
    }
}

.big-image-block__count{
    margin-right: auto;
    margin-left: 24px;
}

.big-image-block__image{
    position: relative;
    width: 100%;
    max-width: 1000px;
    // height: 627px;
    margin: 0 auto;
    background-color: #dbdbdb;


    @media (max-width: 800px) {
        height: auto;
    }
}


.big-image-block__img{
    // object-fit: cover;
    // height: 627px;

    @media (max-width: 800px) {
        height: auto;
    }
}

.big-image-block__prev,
.big-image-block__next{
    position: absolute;
    top: 0;
    width: $width_arrow;
    height: 100%;
    cursor: pointer;
    background-color: #dbdbdb;
    // background-color: gold;
    background-repeat: no-repeat;
    background-size: 78px 78px;

    @media (max-width: 800px) {
        width: 30px;
        background-size: 26px;
    }
}


.big-image-block__prev{
    left: 0;
    background-image: url('/desimages/icons_arrow left.svg');
    background-position: left center;

    &:hover{
        background-image: url('/desimages/icons_arrow left1.svg');
    }
}

.big-image-block__next{
    right: 0;
    background-image: url('/desimages/icons_arrow right.svg');
    background-position: right center;

    &:hover{
        background-image: url('/desimages/icons_arrow right1.svg');
    }
}


//блок маленькие картинки
.slider-thumbs{
    @include reset-ul;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    & > li{
        padding-right: 12px;
        padding-left: 12px;
        margin-bottom: 20px;
    }

    li{
        &::before{
            content: none;
        }
    }

    img{
        @include img-fluid;
        max-width: 170px;
        cursor: pointer;
    }
}

.slider-thumbs__selected{
    opacity: .5;
}


.slider-thumbs__slideshow{
    margin-top: 20px;
    text-align: center;
}

.slider-thumbs__slideshow button{
    font-size: 1.6rem;
    border: 1px solid transparent;
}


.minpr_slider_photo_inner_box{
    margin-top: 90px;
}



/********   */

@media only screen and (max-width: 1560px) {
    .photo_prev {
        left: 5px;
    }

    .photo_next {
        right: 5px;
    }
}

.slider_big_img {
    margin-bottom: .75rem;
}

#slider_rubr_title {
    margin-bottom: 3rem;
}

.slider_photo {
    max-width: 795px;
}

.slider_photo_inner {
    margin: 0 -10px;
    @include media-breakpoint-down(md){
        margin: 0;
    }

    .slick-disabled {
        display: none !important;
    }

    .slick-track {
        .slide {
            padding: 0 10px;

        }
    }
}

.photo_prev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px;
    z-index: 3;
    width: 58px;
    height: 58px;
    margin: auto;
    cursor: pointer;
    background-color: rgb(76, 126, 253);
    border-radius: 50%;
    opacity: .6;
    transition: all .6s;

    &:hover {
        opacity: 1;
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -6px;
        display: block;
        width: 17px;
        height: 25px;
        margin: auto;
        content: '';
        background: url('/desimages/photo_slider_arrow_prev.png') center no-repeat;
    }
}

.photo_next {
    position: absolute;
    top: 0;
    right: -8px;
    bottom: 0;
    z-index: 3;
    width: 58px;
    height: 58px;
    margin: auto;
    cursor: pointer;
    background-color: rgb(76, 126, 253);
    border-radius: 50%;
    opacity: .6;
    transition: all .6s;

    &:hover {
        opacity: 1;
    }

    &::after {
        position: absolute;
        top: 0;
        right: -6px;
        bottom: 0;
        left: 0;
        display: block;
        width: 17px;
        height: 25px;
        margin: auto;
        content: '';
        background: url('/desimages/photo_slider_arrow_next.png') center no-repeat;
    }
}

@media only screen and (max-width: 1560px) {
    .photo_prev {
        left: 5px;
    }

    .photo_next {
        right: 5px;
    }
}

@media only screen and (max-width: 1000px) {
    .photo_prev {
        left: -3px;
    }

    .photo_next {
        right: -3px;
    }
}


.big-image-block__fullscreen{
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    background-image: url("/desimages/fullscreen.png");
}

.big-image-block__name-photo{
    display: none;
}

.big-image-block__download{
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    margin-right: 0;
    margin-left: 10px;
    font-size: 0;
    cursor: pointer;
    background-image: url("/desimages/dowload_icon.png") !important;
}
