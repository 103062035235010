@mixin media-tab {
    .media-tab{
        padding-top: 49px;
        padding-bottom: 50px;

        .active {
            display: block !important;
        }

        .tab-pane{
            display: none;
        }

        a{
            text-decoration: none;

            &:hover{
                text-decoration: none;
            }

        }
    }

    .media-tab ul{
        @include reset-ul;
        display: flex;

    }

    #myTab{
        @include media-breakpoint-down(xs){
            flex-direction: column;
            padding: 0 10px;
        }
    }

    #myTab > li{
        margin-right: 40px;
    }

    .media-tab .title-section__title{
        margin-right: 14px;
    }

    .media-tab .video{
        @include make-row(0);

        & > div{
            @include make-col-ready(0);
            @include make-col(6);
        }

        @include media-breakpoint-down(xs){
            & > div:last-child{
                display: none;
            }

            & > div:first-child{
                @include make-col(12);
            }
        }
    }

    .media-tab .nav{
        padding: 0 66px;
    }

    //*************фото на главной
    .photo-video-block-main{
        position: relative;

        a{
            display: block;
        }
    }

}

@include media-tab();

// ссылки на инет ресурсы
//************************

.list-inet{
    padding-top: 2.5rem;
    background-color: #faf1e5;
}

.list-inet__list{
    columns: 3;

    @include media-breakpoint-down(xl){
        columns: 2;
    }
}

.list-inet__list li{
    max-width: 400px;
    padding-left: 3.4rem;

    &::before{
        top: 1.4rem;
        width: 26px;
        height: 2px;
        background-color: #fb9b1f;
    }
}

.list-inet__list a{
    @include font-bold;
    font-size: 1.3rem;
    color: $color-black;

    &:hover{
        color: $color-hover;
        text-decoration: underline;
    }
}

.list-inet-title{
    @include font-bold;
    font-size: 2.4rem;
    color: $color-black;
    text-transform: uppercase;
}

//для слайдера window width < 600
//class is added in script
.list-inet__list-600-js{
    &.list-inet__list{
        columns: initial;
    }

    .list-inet__list li{
        max-width: 100%;
    }
}

.list-inet__list .slick-dots{
    position: absolute;
    @include reset-ul;
}

.list-inet__list .slick-dots{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button{
        width: 130px;
        height: 2px;
        font-size: 0;
        background-color: #687c29;
        border: 1px solid transparent;

        @include media-breakpoint-down(sm){
            width: 78px;
        }

        &:hover{
            background-color: #fb9b1f;
        }
    }

    li {
        margin-left: 10px;
    }

    .slick-active button{
        background-color: #fb9b1f;
    }
}


.media-tab .title-section__all{
    @media (max-width: 440px) {
        display: none;
    }
}

.mobile-media-button{
    display: none;

    @media (max-width: 440px) {
        display: inline-block !important;
        margin-top: 30px;
    }
}
