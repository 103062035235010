// БАРАНОВИЧСКИЙ РИК

.site-map{
    padding: 0;
    margin: 0;

    /* Reset list styles */
    list-style-type: none;
}

ul.site-map ul{

}

ul.site-map li{
    padding: 0;
    margin: 0;
    background-image: none;
}

ul.site-map li ul{
    margin: 0;
}

//карта сайта
.js-site-map-row{
    display: flex;
    justify-content: space-between;

    & > span{
        flex-basis: 36%;

        @include media-breakpoint-down(md8){
            flex-basis: 44%;
        }
    }

    @include media-breakpoint-down(xs){
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
    }
}

ul.site-map ul > li{
    padding: 0;
}

//3-ÑƒÑ€Ð¾Ð²ÐµÐ½ÑŒ
ul.site-map ul ul > li{

}

ul.site-map ul li{
    // margin-bottom: 2.5rem;
}

ul.site-map > li{
    position: relative;

    @include media-breakpoint-down(xs){
        padding-left: 0;
        margin-bottom: 30px;
    }
}

.site-map a{
    text-decoration: none;

    &:hover{
        color: $color-hover;
        text-decoration: underline;
    }
}

//Ð·Ð°Ð³Ð¾Ð»Ð¾Ðº
ul.site-map > li > a{
    font-family: $font-family-bold;
    font-size: 2.6rem;
}

ul.site-map ul a{
    // font-weight: bold;
}

ul.site-map ul ul a{
    font-weight: normal;
}

.sitemap_level_item_2{
    position: relative;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    &::before{
        display: none;
    }

}

.sitemap_level_item_2 > a{
    position: relative;
    display: block;
    padding-left: 0 !important;
    @include font-bold;
    font-size: 1.8rem;
    color: $color-black;
}

.sitemap_level_item_3{
    padding-top: 16px !important;
    padding-bottom: 0 !important;
    padding-left: 36px !important;

    &::before{
        top: 2.8rem;
        width: 20px;
    }
}

.sitemap_level_item_3 > a{
    position: relative;
    display: block;
    font-size: 1.6rem;
    color: $color-black;
}

.sitemap_level_item_4{
    padding-left: 20px !important;
}

.sitemap_level_item_4 > a{
    position: relative;
    display: block;
    color: $color-black;
}

.site-map .list_inner_2{
    position: relative;
    padding-top: 30px;
    padding-right: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #587c29;

    &:first-child{
        padding-top: 0;
        border-top: none;
    }

    & > a{
        color: #587c29;
        @include font-bold;
    }

    &::before{
        display: none;
    }
}


.js-site-map-row > span li:last-child{
    &::before{
        content: none;
    }
}

.sitemap_level_item_2:last-child{
    &::before{
        content: none;
    }
}
